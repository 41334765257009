import React from 'react';
import './Dashboard.css';
import {SideBar, SideBarCard} from "../../components";
import {Main} from "../index";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";

export default class Dashboard extends React.Component {
  static defaultProps = {
    hasSecondaryNav: false,
    noSidebar: false,
    isCard: false,
    card: {},
    noHeader: false,
    secondaryNav: ''
  };

  render() {
    return (
        <Main
            contentClassName="py-4" isProfile noHeader={this.props.noHeader} noFooter // onlyCopyRight
            hasSecondaryNav={this.props.hasSecondaryNav} secondaryNav={this.props.secondaryNav}>
          <MDBContainer>
            <MDBRow>
              {!this.props.noSidebar ? <SideBar isCard={this.props.isCard} card={this.props.card}
                                                stateChanged={this.props.stateChanged}
                                                limitsChanged={this.props.limitsChanged}/> : null}
              <MDBCol lg={!this.props.noSidebar ? "9" : "12"}>
                {this.props.children}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </Main>
    );
  }
}
