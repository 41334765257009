import React, {Fragment} from "react";
import {numberWithCommas} from "../../assets/js";
import {IncomeModal, MoneyTransferModal, OutgoingModal, LimitModal, PasswordModal} from "../index";
import {MDBBtn} from "mdbreact";
import swal from "sweetalert";
import {updateCard} from "../../services/UserApi";
import {connect} from "react-redux";

class SideBarCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: props.card,
      status: props.card.status !== undefined ? props.card.status : 0,
      incomeModal: false,
      outgoingModal: false,
      moneyTransferModal: false,
      limitModal: false,
      passwordModal: false
    }
  }

  toggleIncomeModal = () => {
    this.setState({incomeModal: !this.state.incomeModal});
  };

  toggleOutgoingModal = () => {
    this.setState({outgoingModal: !this.state.outgoingModal});
  };

  toggleMoneyTransferModal = () => {
    this.setState({moneyTransferModal: !this.state.moneyTransferModal});
  };

  toggleLimitModal = () => {
    this.setState({limitModal: !this.state.limitModal});
  };

  togglePasswordModal = () => {
    this.setState({passwordModal: !this.state.passwordModal});
  };

  block = () => {
    swal(`از ${this.state.status === 1 ? "مسدود" : "فعال"} کردن کارت اطمینان دارید؟`, {
      buttons: {
        cancel: "لغو",
        catch: {
          text: "تایید",
          value: "OK",
        },
      },
    }).then((value) => {
      if (value === "OK")
        updateCard(this.state.card.number, this.state.status === 1 ? 0 : 1).then(response => {
          if (response.status === 200)
            this.setState({
              card: response.card,
              status: this.state.status === 1 ? 0 : 1
            }, () => this.props.stateChanged(this.state.status));
          else
            swal(response.title, response.message, "error", {button: "تایید"});
        });
    });
  };

  render() {
    const {card} = this.state;
    return (
        <div className="bg-light shadow-sm rounded text-center p-3 mb-4">
          <div className="text-17 text-light my-3"><i className="far fa-credit-card"/></div>
          <h3 className="text-9 font-weight-400">{Object.entries(card).length ? numberWithCommas(parseInt(card.balance) / 10) : 0} تومان</h3>
          <p className="mb-2 text-muted opacity-8">موجودی کارت</p>
          <hr className="mx-n3"/>
          <div className="d-flex">
            <MDBBtn block onClick={this.toggleIncomeModal} color="link" className="p-0">
              شارژ کارت
            </MDBBtn>
            <IncomeModal noBtn card={card} toggle={this.toggleIncomeModal} isOpen={this.state.incomeModal}/>
            {card.type === 1 ?
                <MDBBtn block onClick={this.toggleMoneyTransferModal} color="link" className="p-0">
                  {Object.entries(this.props.user).length ? this.props.user.roleName.toLowerCase() === 'vendor' ? 'شارژ کارت مشتری' : 'انتقال وجه' : null}
                </MDBBtn>
                : null}
            <MoneyTransferModal
                noBtn card={card} toggle={this.toggleMoneyTransferModal}
                isOpen={this.state.moneyTransferModal}
                onMoneyTransfered={amount => {
                  card.balance -= amount;
                  this.setState({moneyTransferModal: false});
                }}/>
          </div>
          <div className="d-flex mt-2">
            {card.type === 1 ?
                <Fragment>
                  <MDBBtn block onClick={this.toggleOutgoingModal} color="link" className="p-0">
                    درخواست تسویه
                  </MDBBtn>
                  <OutgoingModal
                      noBtn card={card} toggle={this.toggleOutgoingModal} isOpen={this.state.outgoingModal}
                      onOutgoingAdded={amount => {
                        card.balance -= amount;
                        this.setState({outgoingModal: false});
                      }}/>
                </Fragment>
                : null}
            <MDBBtn block onClick={this.block} color="link" className="p-0">
              {this.state.status === 1 ? "مسدود" : "فعال"} کردن
            </MDBBtn>
          </div>
          <div className="d-flex mt-2">
            <MDBBtn block onClick={this.toggleLimitModal} color="link" className="p-0">
              تعیین سقف تراکنش
            </MDBBtn>
            <LimitModal noBtn card={card} toggle={this.toggleLimitModal} isOpen={this.state.limitModal}
                        onLimitSet={(simple, daily) => this.props.limitsChanged(simple, daily)}
                        onStateChanged={limitModal => this.setState({limitModal: limitModal})}
            />
            <MDBBtn block onClick={this.togglePasswordModal} color="link" className="p-0">
              تعیین رمز
            </MDBBtn>
            <PasswordModal noBtn card={card} toggle={this.togglePasswordModal} isOpen={this.state.passwordModal}
                           onStateChanged={passwordModal => this.setState({passwordModal: passwordModal})}/>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

export default connect(mapStateToProps, null)(SideBarCard);
