import React from 'react';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";

export default class WhatCanYouDo extends React.Component {
  render() {
    return (
        <section id="WhatCanYouDo" className="section bg-white">
          <MDBContainer>
            <h2 className="text-9 text-center text-uppercase font-weight-400">چه کارهایی می‌توانید انجام دهید</h2>
            <p className="text-4 text-center font-weight-300 mb-5">Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.</p>
            <MDBRow>
              <MDBCol sm="6" lg="3" className="mb-4">
                <a href="#">
                  <div className="featured-box style-5 rounded">
                    <div className="featured-box-icon text-primary"><i className="fas fa-share-square"/></div>
                    <h3>Send Money</h3>
                  </div>
                </a>
              </MDBCol>
              <MDBCol sm="6" lg="3" className="mb-4">
                <a href="#">
                  <div className="featured-box style-5 rounded">
                    <div className="featured-box-icon text-primary"><i className="fas fa-check-square"/></div>
                    <h3>Receive Money</h3>
                  </div>
                </a>
              </MDBCol>
              <MDBCol sm="6" lg="3" className="mb-4">
                <a href="#">
                  <div className="featured-box style-5 rounded">
                    <div className="featured-box-icon text-primary"><i className="fas fa-user-friends"/></div>
                    <h3>Pay a Friend</h3>
                  </div>
                </a>
              </MDBCol>
              <MDBCol sm="6" lg="3" className="mb-4">
                <a href="#">
                  <div className="featured-box style-5 rounded">
                    <div className="featured-box-icon text-primary"><i className="fas fa-shopping-bag"/></div>
                    <h3>Online Shopping</h3>
                  </div>
                </a>
              </MDBCol>
            </MDBRow>
            <div className="text-center mt-4"><a href="#" className="btn-link text-4">See more can you do<i
                className="fas fa-chevron-left text-2 mr-2"/></a></div>
          </MDBContainer>
        </section>
    );
  }
}