import React from "react";
import {DashboardLayout} from "../../layouts";
import {connect} from "react-redux";
import {getUserCards} from "../../services/UserApi";
import {CardModal} from "../../components";
import {Card} from "../../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {swal} from "../../assets/js";
import {store} from "../../store";
import {removeFromStorage} from "../../services/Storage";

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vendorCards: [],
      customerCards: []
    };
  }

  componentDidMount() {
    getUserCards().then(response => {
      if (response.status === 200)
        this.setState({
          vendorCards: response.cards.filter(card => card.type === 1),
          customerCards: response.cards.filter(card => card.type === 0),
          loading: false
        });
      else {
        this.setState({loading: false});
        swal(response.title, response.message, "error", "تایید");
      }
    }).catch(error => {
    });
  }

  addCard = card => {
    let cards = Array.from(this.state.customerCards);
    cards.push(card);
    this.setState({customerCards: cards});
  };

  render() {
    return (
        <DashboardLayout>
          {Object.entries(this.props.user).length ? this.props.user.roleName.toLowerCase() === "vendor" ?
              <div className="bg-light shadow-sm rounded p-4 mb-4">
                <h3 className="text-5 font-weight-400 mb-4">
                  کارت های کاری من
                </h3>
                <div className="row">
                  {this.state.loading ?
                      <CircularProgress color="primary"/>
                      :
                      this.state.vendorCards.length ?
                          this.state.vendorCards.map((card, index) => {
                            return (
                                <Card key={index} card={card}/>
                            );
                          })
                          :
                          <p>کارتی یافت نشد.</p>
                  }
                </div>
              </div>
              : null : null}
          <div className="bg-light shadow-sm rounded p-4 mb-4">
            <h3 className="text-5 font-weight-400 mb-4">
              کارت
              های {Object.entries(this.props.user).length ? this.props.user.roleName.toLowerCase() === 'vendor' ? 'شخصی' : null : null} من
            </h3>
            <div className="row">
              {this.state.loading ?
                  <CircularProgress color="primary"/>
                  :
                  this.state.customerCards.length ?
                      this.state.customerCards.map((card, index) => {
                        return (
                            <Card key={index} card={card}/>
                        );
                      }) : null}
              <div className="col-12 col-sm-6 col-lg-4 mb-4">
                <CardModal onCardAdded={this.addCard}/>
              </div>
            </div>
          </div>
        </DashboardLayout>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

export default connect(mapStateToProps, null)(Cards);
