import React from "react";
import {Main} from "../../layouts";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";

export default class ContactUs extends React.Component {
  render() {
    return (
        <Main isPage title="تماس با ما">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="4" className="mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4"><i className="fas fa-map-marker-alt"/>
                    </div>
                    <h3>همیان Inc.</h3>
                    <p>4th Floor, Plot No.22, Above Public Park<br/>
                      145 Murphy Canyon Rd.<br/>
                      Suite 100-18<br/>
                      San Diego CA 2028</p>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="4" className="mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4"><i className="fas fa-phone"/></div>
                    <h3>Telephone</h3>
                    <p className="mb-0">(+060) 9898980098</p>
                    <p>(+060) 8898880088</p>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="4" className="mb-4">
                <div className="bg-white shadow-md rounded h-100 p-3">
                  <div className="featured-box text-center">
                    <div className="featured-box-icon text-primary mt-4"><i className="fas fa-envelope"/></div>
                    <h3>Business Inquiries</h3>
                    <p>info@payyed.com</p>
                  </div>
                </div>
              </MDBCol>
              <MDBCol size="12" className="mb-4">
                <div className="text-center py-5 px-2">
                  <h2 className="text-8">Get in touch</h2>
                  <p className="lead">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <div className="d-flex flex-column">
                    <ul className="social-icons social-icons-lg social-icons-colored justify-content-center">
                      <li className="social-icons-facebook"><a href="http://www.facebook.com/"
                                                               target="_blank" title=""
                      ><i
                          className="fab fa-facebook-f"/></a></li>
                      <li className="social-icons-twitter"><a href="http://www.twitter.com/"
                                                              target="_blank" title=""><i
                          className="fab fa-twitter"/></a></li>
                      <li className="social-icons-google"><a href="http://www.google.com/"
                                                             target="_blank" title=""><i
                          className="fab fa-google"/></a></li>
                      <li className="social-icons-linkedin"><a href="http://www.linkedin.com/"
                                                               target="_blank" title=""
                      ><i
                          className="fab fa-linkedin-in"/></a></li>
                      <li className="social-icons-youtube"><a href="http://www.youtube.com/"
                                                              target="_blank" title=""><i
                          className="fab fa-youtube"/></a></li>
                      <li className="social-icons-instagram"><a href="http://www.instagram.com/"
                                                                target="_blank" title=""
                      ><i
                          className="fab fa-instagram"/></a></li>
                    </ul>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <section className="hero-wrap section shadow-md">
            <div className="hero-mask opacity-7 bg-dark"/>
            <div className="hero-bg"
                 style={{backgroundImage: "url('http://demo.harnishdesign.net/html/payyed/images/bg/image-2.jpg')"}}/>
            <div className="hero-content">
              <div className="container text-center">
                <h2 className="text-9 text-white">Awesome Customer Support</h2>
                <p className="text-4 text-white mb-4">Have you any query? Don't worry. We have great people ready to
                  help you whenever you need it.</p>
                <a href="#" className="btn btn-light">Find out more</a></div>
            </div>
          </section>
        </Main>
    );
  }
}