import React from 'react';
import {AnimatedRoute, spring} from 'react-router-transition';

export default class Route extends React.Component {
  slide = val => {
    return spring(val, {
      stiffness: 125,
      damping: 16,
    });
  };

  render() {
    return (
        <AnimatedRoute
            path={this.props.path}
            exact={this.props.exact}
            render={this.props.render}
            component={this.props.component}
            atEnter={{offset: -100}}
            atLeave={{offset: this.slide(-100)}}
            atActive={{offset: this.slide(0)}}
            mapStyles={styles => ({
              transform: `translateX(${styles.offset}%)`,
            })}
        />
    );
  }
}