import React from "react";
import {DashboardLayout} from "../../layouts";
import {connect} from "react-redux";
import moment from "moment-jalaali";
import {DatePicker} from "react-advance-jalaali-datepicker";
import {MDBBtn, MDBCol, MDBInput, MDBRow} from "mdbreact";
import {updateUser} from "../../services/UserApi";
import {setInStorage} from "../../services/Storage";
import {swal} from "../../assets/js";
import {DropZone} from "../../components";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.user.firstName ? props.user.firstName : '',
      lastName: props.user.lastName ? props.user.lastName : '',
      email: props.user.email ? props.user.email : '',
      birthDate: props.user.birthDate ? moment(props.user.birthDate, 'YYYY-MM-DD').format('jYYYY/jMM/jDD') : '',
      gender: props.user.gender ? props.user.gender : '',
      address: props.user.address ? props.user.address : '',
      avatar: props.user.avatar ? props.user.avatar : '',
      loading: false
    };
  }

  updateUser = e => {
    e.preventDefault();
    this.setState({loading: true});
    const avatar = (this.state.avatar.length && this.state.avatar[0] && this.state.avatar[0] instanceof Blob) ? this.state.avatar[0].data : this.state.avatar.startsWith('http') ? '' : '';
    updateUser(avatar, this.state.firstName, this.state.lastName, this.state.email, this.state.birthDate ? moment(this.state.birthDate, 'jYYYY/jMM/jDD').format('YYYY/MM/DD') : '', this.state.gender ? 1 : 0, this.state.address).then(response => {
      if (response.status === 200) {
        const user = {
          access_token: this.props.user.access_token,
          firstName: response.user.firstName,
          lastName: response.user.lastName,
          roleName: response.user.roleName,
          status: response.user.status,
          nationalCode: response.user.nationalCode,
          bankAccount: response.user.bankAccount,
          phone: response.user.phone,
          email: response.user.email,
          emailVerifiedAt: response.user.emailVerifiedAt,
          birthDate: response.user.birthDate,
          gender: response.user.gender,
          address: response.user.address,
          avatar: response.user.avatar,
          score: response.user.score,
          createdAt: response.user.jalaliCreatedAt,
          updatedAt: response.user.updatedAt,
          wallet: response.user.wallet
        };
        this.props.updateUser(user);
        setInStorage('user', user);
        swal(response.title, response.message, "success", "تایید");
      } else
        swal(response.title, response.message, "error", "تایید");
      this.setState({loading: false});
    }).catch(error => {
      console.log(JSON.stringify(error))
    });
  };

  render() {
    const {user} = this.props;
    if (user.roleName.toLowerCase() === "vendor")
      return (
          <DashboardLayout hasSecondaryNav={false}
              // secondaryNav={
              // <MDBNav className="secondary-nav">
              //   <MDBNavItem>
              //     <MDBLink className="nav-link" href="/profile">اطلاعات پروفایل</MDBLink>
              //   </MDBNavItem>
              //   <MDBNavItem>
              //     <MDBLink className="nav-link" to="/cards">کارت های من</MDBLink>
              //   </MDBNavItem>
              //   <MDBNavItem className="nav-item">
              //     <MDBLink className="nav-link" href="/transactions">تراکنش ها</MDBLink>
              //   </MDBNavItem>
              // </MDBNav>
              // }
          >
            <div className="bg-light shadow-sm rounded p-4 mb-4">
              <h3 className="text-5 font-weight-400 mb-3">اطلاعات شخصی
                {/*<a href="#edit-personal-details" data-toggle="modal"*/}
                {/*   className="float-left text-1 text-uppercase text-muted btn-link">*/}
                {/*  <i className="fas fa-edit ml-1"/> ویرایش*/}
                {/*</a>*/}
              </h3>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">نام و نام خانوادگی</p>
                <p className="col-sm-9">{user.firstName + ' ' + user.lastName}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">شماره موبایل</p>
                <p className="col-sm-9">{user.phone}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">تاریخ تولد</p>
                <p className="col-sm-9">
                  {moment(user.birthDate, 'YYYY-MM-DD').format('jYYYY/jMM/jDD')}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">کد ملی</p>
                <p className="col-sm-9">{user.nationalCode}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">شماره شبا</p>
                <p className="col-sm-9">{user.bankAccount}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">جنسیت</p>
                <p className="col-sm-9">{user.gender ? 'مرد' : 'زن'}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">ایمیل</p>
                <p className="col-sm-9">{user.email}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">تاریخ عضویت</p>
                <p className="col-sm-9" style={{direction: 'ltr'}}>{user.createdAt}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">آدرس</p>
                <p className="col-sm-9">{user.address}</p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">وضعیت حساب کاربری</p>
                <p className="col-sm-9">
                <span
                    className={`bg-${user.status ? 'success' : 'danger'} text-white rounded-pill d-inline-block px-2 mb-0`}>
                  <i className={`fas fa-${user.status ? 'check-circle' : 'ban'}`}/> {user.status ? 'فعال' : 'غیرفعال'}
                </span>
                </p>
              </div>
            </div>
          </DashboardLayout>
      );
    else
      return (
          <DashboardLayout hasSecondaryNav={false}>
            <div className="bg-light shadow-sm rounded p-4 mb-4">
              <h3 className="text-5 font-weight-400 mb-5">اطلاعات شخصی</h3>
              <MDBRow>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <MDBInput
                      id="firstName" label="نام" labelId="firstNameLabel" icon="user" group
                      type="text" validate error="wrong" success="right" name="firstName"
                      value={this.state.firstName} onInput={e => this.setState({firstName: e.target.value})}
                  />
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <MDBInput
                      id="lastName" label="نام خانوادگی" labelId="lastNameLabel" icon="user"
                      type="text" validate error="wrong" success="right" name="lastName" group
                      value={this.state.lastName} onInput={e => this.setState({lastName: e.target.value})}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <MDBInput
                      id="phone" label="شماره موبایل" labelId="phoneLabel" icon="phone"
                      group name="phone" value={this.props.user.phone.substr(1)} disabled={true}
                      containerClass="parent-disabled" style={{direction: "ltr"}}
                      className="d-inline-block font-weight-bold input-font-spacing pl-5 text-6">
                    <span className="font-weight-bold input-font-spacing mr-n5 text-6">98+</span>
                  </MDBInput>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <DatePicker
                      inputComponent={props =>
                          <MDBInput
                              label="تاریخ تولد" labelId="birthDateLabel" icon="calendar"
                              group name="birthDate" {...props} value={this.state.birthDate}
                          />
                      }
                      placeholder={this.state.birthDate ? this.state.birthDate : "تاریخ تولد"}
                      value={this.state.birthDate} format="jYYYY/jMM/jDD"
                      onChange={(unix, formatted) => this.setState({birthDate: formatted})}
                      id="birthDate" cancelOnBackgroundClick={true}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <MDBInput
                      id="email" label="پست الکترونیک" labelId="emailLabel" icon="envelope" group
                      type="email" validate error="wrong" success="right" name="email" style={{direction: "ltr"}}
                      value={this.state.email} onInput={e => this.setState({email: e.target.value})}
                  />
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">جنسیت</FormLabel>
                    <RadioGroup row name="gender" value={this.state.gender}
                                onChange={e => this.setState({gender: e.target.value === "true"})}>
                      <FormControlLabel value={true} control={<Radio/>} label="مرد"/>
                      <FormControlLabel value={false} control={<Radio/>} label="زن"/>
                    </RadioGroup>
                  </FormControl>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <MDBInput
                      id="address" label="آدرس" labelId="addressLabel" icon="map-marker-alt" group
                      type="textarea" rows="5" validate error="wrong" success="right" name="address"
                      value={this.state.address} onInput={e => this.setState({address: e.target.value})}
                  />
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" lg="6" xl="6">
                  <p className="material-form__label">تصویر پروفایل</p>
                  <DropZone
                      name="avatar" value={this.state.avatar}
                      onChange={encodedFile => this.setState({avatar: encodedFile})}
                  />
                </MDBCol>
              </MDBRow>
              {this.state.loading ? <CircularProgress color="primary"/> :
                  <MDBBtn className="mt-4" onClick={this.updateUser}>به روز رسانی پروفایل</MDBBtn>
              }
            </div>
          </DashboardLayout>
      );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: user => dispatch({type: "UPDATE", payload: user})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
