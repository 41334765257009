import React from "react";
import profile from "../../assets/images/profile-thumb.jpg";
import {MDBLink} from "mdbreact";

export default class SideBarProfile extends React.Component {
  render() {
    const name = this.props.user.firstName && this.props.user.lastName ? this.props.user.firstName + ' ' + this.props.user.lastName : this.props.user.phone;
    return (
        <div className="bg-light shadow-sm rounded text-center p-3 mb-4">
          <div className="profile-thumb mt-3 mb-4">
            <img className="rounded-circle img-fluid" src={this.props.user.avatar ? this.props.user.avatar : profile} alt=""/>
            {/*<div className="profile-thumb-edit custom-file bg-primary text-white"*/}
            {/*     title="تغییر تصویر پروفایل">*/}
            {/*  <i className="fas fa-camera position-absolute"/>*/}
            {/*  <input type="file" className="custom-file-input" id="customFile"/>*/}
            {/*</div>*/}
          </div>
          <p className="text-3 font-weight-500 mb-2">سلام، {name}</p>
          <MDBLink to="/profile" className="text-2 text-light" title="ویرایش اطلاعات">
            <i className="fas fa-user"/> مشاهده پروفایل
          </MDBLink>
        </div>
    );
  }
}
