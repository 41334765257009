import React from 'react';
import {MDBCol, MDBContainer, MDBLink, MDBRow} from "mdbreact";

export default class WhyChooseUs extends React.Component {
  render() {
    return (
        <section id="WhyChooseUs" className="section bg-white">
          <MDBContainer>
            <h2 className="text-9 text-center text-uppercase font-weight-400">چرا همیان؟</h2>
            <p className="text-4 text-center font-weight-300 mb-5">
              چهار دلیل اصلی که به کیف پول دیجیتال همیان نیاز دارید
            </p>
            <MDBRow>
              <MDBCol lg="10" className="mx-auto">
                <MDBRow>
                  <MDBCol sm="6" className="mb-4">
                    <div className="featured-box style-3">
                      <div className="featured-box-icon border border-primary text-primary rounded-circle"><i
                          className="fas fa-heartbeat"/></div>
                      <h3 className="font-weight-400">کمک به سلامت عمومی</h3>
                      <p>با حذف پول فیزیکی، خود و همشهریان را از خطر انتقال بیماری های واگیردار دور کنید.</p>
                    </div>
                  </MDBCol>
                  <MDBCol sm="6" className="mb-4">
                    <div className="featured-box style-3">
                      <div className="featured-box-icon border border-primary text-primary rounded-circle"><i
                          className="fas fa-bolt"/></div>
                      <h3 className="font-weight-400">پرداخت سریع</h3>
                      <p>به سرعت و تنها با نزدیک کردن کارت مشتری به تلفن همراه خود، وجه را دریافت کنید.</p>
                    </div>
                  </MDBCol>
                  <MDBCol sm="6" className="mb-4 mb-sm-0">
                    <div className="featured-box style-3">
                      <div className="featured-box-icon border border-primary text-primary rounded-circle"><i
                          className="fas fa-hand-holding-usd"/></div>
                      <h3 className="font-weight-400">انتقال وجه سریع</h3>
                      <p>به سادگی با انتخاب یک گزینه، موجودی کیف پول خود را به حساب بانکی منتقل کنید.</p>
                    </div>
                  </MDBCol>
                  <MDBCol sm="6">
                    <div className="featured-box style-3">
                      <div className="featured-box-icon border border-primary text-primary rounded-circle"><i
                          className="fas fa-wallet"/></div>
                      <h3 className="font-weight-400">پول خرد همراه شما</h3>
                      <p>مشکل کمبود پول خرد را حل کنید.</p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <div className="text-center mt-4">
              <MDBLink to="/about" className="btn-link text-4">اطلاعات بیشتر<i
                  className="fas fa-chevron-left text-2 mr-2"/></MDBLink>
            </div>
          </MDBContainer>
        </section>
    );
  }
}
