import React, {Component} from 'react';
import {MDBBtn, MDBModal, MDBModalHeader} from 'mdbreact';
import './Modal.css';

class ModalPage extends Component {
  static defaultProps = {
    id: '',
    size: '',
    isOpen: false,
    noHeader: false,
    noBtn: false,
    btnText: '',
    btnColor: '',
    wrapperClassName: '',
    btnClassName: '',
    toggle: () => null,
    title: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.isOpen !== nextProps.isOpen)
      this.toggle(nextProps.isOpen);
  }

  toggle = isOpen => {
    this.setState({isOpen: isOpen});
  };

  render() {
    if (!this.props.noBtn)
      return (
          <div className={this.props.wrapperClassName}>
            <MDBBtn block onClick={this.props.toggle} color={this.props.btnColor} className={this.props.btnClassName}>
              {this.props.btnText}
            </MDBBtn>
            <MDBModal
                id={this.props.id} size={this.props.size} isOpen={this.state.isOpen} toggle={this.props.toggle}
                className={this.props.dialogClassName} overflowScroll={false} centered>
              {!this.props.noHeader ?
                  <MDBModalHeader toggle={this.props.toggle}>{this.props.title}</MDBModalHeader>
                  : null}
              {this.props.children}
            </MDBModal>
          </div>
      );
    else
      return (
          <MDBModal
              id={this.props.id} size={this.props.size} isOpen={this.state.isOpen} toggle={this.props.toggle}
              overflowScroll={false} centered>
            {!this.props.noHeader ?
                <MDBModalHeader toggle={this.props.toggle}>{this.props.title}</MDBModalHeader>
                : null}
            {this.props.children}
          </MDBModal>
      );
  }
}

export default ModalPage;