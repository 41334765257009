import React from 'react';
import {AnimatedSwitch} from 'react-router-transition';

export default class Switch extends React.Component {
  render() {
    return (
        <AnimatedSwitch
            atEnter={{opacity: 0}}
            atLeave={{opacity: 0}}
            atActive={{opacity: 1}}
            mapStyles={styles => ({
              transform: `translateX(${styles.offset}%)`,
            })}
        >
          {this.props.children}
        </AnimatedSwitch>
    );
  }
}