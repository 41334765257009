import React from 'react';
import moment from "moment-jalaali";
import {dashed, numberWithCommas, swal} from "../../assets/js";
import {Main, DashboardLayout} from "../../layouts";
import logo from "../../assets/images/logo.png";
import {withRouter} from "react-router-dom";
import {getCard, getCardRecentTransactions, getCardTransactions} from "../../services/UserApi";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Loading, DataTable} from "../../components";
import TransactionRow from "./components/TransactionRow";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Col, Label} from 'reactstrap';

class CardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {},
      status: 0,
      chartData: [],
      headCells: [
        {id: 'transId', label: 'کد رهگیری', sortable: false},
        {id: 'CreatedAt', label: 'تاریخ ثبت', sortable: true},
        // {id: 'source', label: 'مبداء'},
        // {id: 'destination', label: 'مقصد'},
        {id: 'amount', label: 'مبلغ (تومان)', sortable: false},
        {id: 'Balance', label: 'موجودی (تومان)', sortable: true},
        // {id: 'description', label: 'توضیحات'}
        {id: 'details', label: 'جزییات', sortable: false}
      ],
      rows: [],
      total: 1,
      perPage: 5,
      page: 0,
      pages: '',
      orderBy: 'CreatedAt',
      order: 'desc',
      search: '',
      amountFrom: "",
      amountTo: "",
      dateFrom: "",
      dateTo: "",
      type: "",
      types: [
        {value: 0, label: "واریز"},
        {value: 1, label: "برداشت"},
        {value: 2, label: "پرداخت از درگاه بانکی"}
      ],
      loading: false
    }
  }

  componentDidMount() {
    if (this.props.match.params.number.toString().length === 16)
      this.fetchCard();
    else
      this.props.history.push('/cards');
  }

  fetchCard = () => {
    this.setState({loading: true});
    getCard(this.props.match.params.number).then(response => {
      if (response.status === 200)
        this.setState({card: response.card, status: response.card.status}, () => {
              // let balance = this.state.card.balance / 10;
              if (this.state.card.type === 1)
                this.setState({
                  types: [...this.state.types, ...[
                    {value: 3, label: "درخواست تسویه"},
                    {value: 4, label: "کارمزد شارژ کارت مشتری"},
                    {value: 5, label: "کارمزد تسویه"}
                  ]]
                  //, chartData: [
                  //   {hour: '0-4', balance: 0},
                  //   {hour: '4-8', balance: parseInt(balance - (balance * (4 / 7)))},
                  //   {hour: '8-12', balance: parseInt(balance - (balance * (3 / 7)))},
                  //   {hour: '12-16', balance: parseInt(balance - (balance * (2 / 7)))},
                  //   {hour: '16-20', balance: parseInt(balance - (balance * (1 / 7)))},
                  //   {hour: '20-24', balance: balance}
                  // ]
                });
              // else
              // this.setState({
              //   chartData: [
              //     {hour: '0-4', balance: 0},
              //     {hour: '4-8', balance: parseInt(balance - (balance * (4 / 7)))},
              //     {hour: '8-12', balance: parseInt(balance - (balance * (3 / 7)))},
              //     {hour: '12-16', balance: parseInt(balance - (balance * (2 / 7)))},
              //     {hour: '16-20', balance: parseInt(balance - (balance * (1 / 7)))},
              //     {hour: '20-24', balance: balance}
              //   ]
              // });
              this.fetchRecentTransactions();
              this.fetchTransactions();
            }
        );
      else
        this.props.history.push('/cards');
    }).catch(error => {
      this.setState({loading: false});
      console.log(error);
    });
  };

  fetchRecentTransactions = () => {
    getCardRecentTransactions(this.state.card.number).then(response => {
      if (response.status === 200)
        this.setState({chartData: response.transactions});
    });
  };

  fetchTransactions = () => {
    this.setState({loading: true});
    getCardTransactions(this.state.card.number, this.state.perPage, parseInt(this.state.page) + 1, this.state.orderBy, this.state.order, this.state.search, this.state.dateFrom, this.state.dateTo, this.state.amountFrom, this.state.amountTo, this.state.type).then(response => {
      if (response.status === 200)
        this.setState({rows: response.transactions.data, total: response.transactions.total, loading: false});
      else
        swal(response.title, response.message, "error", {button: "تایید"});
    }).catch(error => {
      this.setState({loading: false});
      console.log(error);
    });
  };

  onPageChange = page => this.setState({page: page}, () => this.fetchTransactions());

  onPageSizeChange = pageSize => this.setState({perPage: pageSize}, () => this.fetchTransactions());

  sort = value => this.setState({order: value}, () => this.fetchTransactions());

  sortBy = value => this.setState({orderBy: value}, () => this.fetchTransactions());

  search = value => {
    // this.setState({search: value}, () => this.fetchTransactions());
  };

  render() {
    const {card} = this.state;
    return (
        Object.entries(card).length ?
            <DashboardLayout isCard card={this.state.card} stateChanged={status => this.setState({status: status})}
                             limitsChanged={(simple, daily) => {
                               card.simpleTransactionLimit = simple;
                               card.dailyTransactionLimit = daily;
                               this.setState({card: card});
                             }}>
              <div className="row no-gutters bg-light shadow-sm rounded p-4 mb-4">
                <div className="col-sm-5 d-flex justify-content-center">
                  <div className="my-auto text-center w-100 bg-primary rounded-left pt-3">
                    <div className="text-17 text-white"><i className="far fa-credit-card"/></div>
                    <div className="d-flex justify-content-center">
                      <img src={logo} width="auto" height="50" alt="همیان" className="my-auto"/>
                      <p className="text-6 text-white mr-2 my-2 font-weight-bold ">همیان</p>
                    </div>
                    <div className="text-4 text-white my-3 font-weight-bold">{dashed(card.number)}</div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <h5 className="text-5 font-weight-400 m-3">جزییات اطلاعات کارت</h5>
                  <hr/>
                  <div className="px-3">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <ul className="list-unstyled">
                          <li className="font-weight-500">نوع کارت:</li>
                          <li className="text-muted">{card.type === 0 ? 'شخصی' : 'کاری'}</li>
                        </ul>
                        <ul className="list-unstyled">
                          <li className="font-weight-500">شماره کارت:</li>
                          <li className="text-muted">{dashed(card.number)}</li>
                        </ul>
                        <ul className="list-unstyled">
                          <li className="font-weight-500">تاریخ صدور:</li>
                          <li className="text-muted" style={{direction: 'ltr'}}>
                            {moment(card.createdAt, 'YYYY-MM-DD HH:mm').format('jYYYY/jMM/jDD , HH:mm')}
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-12">
                        <ul className="list-unstyled">
                          <li className="font-weight-500">سقف هر تراکنش:</li>
                          <li className="text-muted">{card.simpleTransactionLimit ? numberWithCommas(parseInt(card.simpleTransactionLimit) / 10) : 0} تومان</li>
                        </ul>
                        <ul className="list-unstyled">
                          <li className="font-weight-500">سقف تراکنش‌های روزانه:</li>
                          <li className="text-muted">{card.dailyTransactionLimit ? numberWithCommas(parseInt(card.dailyTransactionLimit) / 10) : 0} تومان</li>
                        </ul>
                        <ul className="list-unstyled">
                          <li className="font-weight-500">وضعیت:</li>
                          <li className="text-muted">
                               <span className={`text-${this.state.status === 1 ? 'success' : 'danger'} text-3`}><i
                                   className={`fas fa-${this.state.status === 1 ? 'check-circle' : 'ban'} align-middle ml-1`}/></span>
                            {this.state.status === 1 ? "فعال" : "مسدود"}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-light shadow-sm rounded p-4 pb-5 mb-4" style={{direction: 'ltr', height: 400}}>
                <h3 className="text-5 font-weight-400 d-flex align-items-center px-4 mb-3" style={{direction: 'rtl'}}>
                  تراکنش های امروز
                </h3>
                <ResponsiveContainer>
                  <AreaChart data={this.state.chartData} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="jalaliCreatedAtTime"/>
                    <YAxis/>
                    <Tooltip/>
                    <Area type="monotone" dataKey="balance" stroke="#91703B" fill="#DAA520"/>
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <DataTable
                  count={this.state.total}
                  headCells={this.state.headCells}
                  rows={this.state.rows}
                  rowsPerPage={this.state.perPage}
                  page={this.state.page}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  loading={this.state.loading}
                  onPageChange={this.onPageChange}
                  onPageSizeChange={this.onPageSizeChange}
                  sort={this.sort}
                  sortBy={this.sortBy}
                  search={this.search}
                  title="تراکنش‌های کارت"
                  renderToolbar={() =>
                      <div className="d-sm-flex w-100 justify-content-between">
                        <FormGroup row className="w-100">
                          <Label for="sort" sm={4} className="px-0">مرتب سازی بر اساس</Label>
                          <Col sm={5} className="pr-0">
                            <FormControl className="w-100">
                              <Select
                                  value={this.state.orderBy} name="sort"
                                  onChange={e => this.setState({orderBy: e.target.value}, () => this.fetchTransactions())}
                              >
                                {this.state.headCells.filter(item => item.sortable).map((cell, index) => {
                                  return (
                                      <MenuItem key={cell.id} value={cell.id}>{cell.label}</MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col sm={3} className="px-0">
                            <FormControl className="w-100">
                              <Select
                                  value={this.state.order} name="order"
                                  onChange={e => this.setState({order: e.target.value}, () => this.fetchTransactions())}
                              >
                                <MenuItem value="asc">صعودی</MenuItem>
                                <MenuItem value="desc">نزولی</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                        </FormGroup>
                        <FormGroup row className="w-100">
                          <Label for="type" sm={3}>نوع تراکنش</Label>
                          <Col sm={9}>
                            <FormControl className="w-100">
                              <Select
                                  value={this.state.type}
                                  onChange={e => this.setState({type: e.target.value}, () => this.fetchTransactions())}
                                  displayEmpty name="type"
                              >
                                <MenuItem value="">همه</MenuItem>
                                {this.state.types.map((type, index) => {
                                  return (
                                      <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                        </FormGroup>
                      </div>
                  }
                  renderCells={row => <TransactionRow row={row}/>}
              />
            </DashboardLayout>
            :
            <Main>
              <Loading/>
            </Main>
    );
  }
}

export default withRouter(CardDetails);
