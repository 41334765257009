import React from "react";
import {DashboardLayout} from "../../layouts";

export default class Notifications extends React.Component {
  render() {
    return (
        <DashboardLayout>
          <div className="bg-light shadow-sm rounded p-4 mb-4">
            <h3 className="text-5 font-weight-400">اعلانات</h3>
            {/*<p className="text-muted">Select subscriptions to be delivered to <span*/}
            {/*    className="text-body">smithrhodes1982@gmail.com</span></p>*/}
            <hr className="mx-n4"/>

            <h3 className="text-5 font-weight-400 text-center">این قابلیت در دست توسعه می باشد.</h3>

            {/*<form id="notifications" method="post">*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="announcements" name="notifications" className="custom-control-input" type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="announcements">Announcements</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Be the first to know about new features and other*/}
            {/*      news.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="sendPayment" name="notifications" className="custom-control-input" type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="sendPayment">Send payment</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Send an email when I send a payment.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="receiveApayment" name="notifications" className="custom-control-input" type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="receiveApayment">Receive a*/}
            {/*      payment</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Send me an email when I receive a payment.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="requestPayment" name="notifications" className="custom-control-input"*/}
            {/*           type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="requestPayment">Request*/}
            {/*      payment</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Send me an email when i request payment.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="problemWithPayment" name="notifications" className="custom-control-input"*/}
            {/*           type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="problemWithPayment">Have a problem*/}
            {/*      with a payment</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Send me an email when have a problem with a*/}
            {/*      payment.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="specialOffers" name="notifications" className="custom-control-input"*/}
            {/*           type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="specialOffers">Special*/}
            {/*      Offers</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Receive last-minute offers from us.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <div className="form-check custom-control custom-checkbox">*/}
            {/*    <input id="reviewSurveys" name="notifications" className="custom-control-input"*/}
            {/*           type="checkbox"/>*/}
            {/*    <label className="custom-control-label text-3" htmlFor="reviewSurveys">Review*/}
            {/*      Surveys</label>*/}
            {/*    <p className="text-muted line-height-3 mt-2">Share your payment experience to better*/}
            {/*      inform users.</p>*/}
            {/*  </div>*/}
            {/*  <hr className="mx-n4"/>*/}
            {/*  <button className="btn btn-primary mt-1" type="submit">Save Changes</button>*/}
            {/*</form>*/}
          </div>
        </DashboardLayout>
    );
  }
}
