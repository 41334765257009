import React from "react";
import "./Login.css";
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBLink, MDBRow} from "mdbreact";
import {numbersToEn, swal} from "../../assets/js";
import image3 from '../../assets/images/image-3.jpg';
// import logo from '../../assets/images/logo.png';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Countdown from "react-countdown-now";
import {removeFromStorage, setInStorage} from "../../services/Storage";
import {checkPhone, checkRole, verifyCode} from "../../services/GuestApi";
import {getUser, passwordAuth} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../assets/images/logo.png";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      key: 1,
      title: 'لطفا شماره موبایل خود را وارد کنید',
      phone: '',
      code: '',
      verification: '',
      verificationTimer: 10000,
      timerCreatedAt: Date.now(),
      loading: false,
      error: ''
    };
  }

  componentDidMount() {
    const getResult = localStorage.getItem('timerCreated');
    if (getResult) {
      const parsedResult = JSON.parse(getResult);
      if (((parsedResult[0] + this.state.verificationTimer) - Date.now()) > 0)
        this.setState({
          timerCreatedAt: parsedResult[0],
          phone: parsedResult[1],
          step: 2,
          title: 'لطفا کدی که برای شما پیامک شده را وارد کنید',
        });
      else
        localStorage.removeItem('timerCreated');
    }
  }

  updatePhone = e => {
    if (this.state.phone.length === 0 && (e.target.value === '0' || e.target.value === '۰'))
      return false;
    else
      this.setState({phone: numbersToEn(e.target.value)});
  };

  checkPhone = e => {
    e.preventDefault();
    if (this.state.phone.length === 10)
      this.setState({loading: true}, () => {
        checkPhone('0' + this.state.phone).then(response => {
          //   if (response.status === 200) {
          //     if (response.user) {
          //       if (response.user.role.slug === 'customer')
          if (response.status === 500) {
            swal("", "خطایی در ارسال کد رخ داده است.", "error", "تایید");
            this.setState({loading: false});
          } else if (response) {
            this.setState({
              timerCreatedAt: Date.now(),
              key: parseInt(this.state.key) + 1,
              verificationTimer: 10000,
              step: 2,
              title: 'لطفا کدی که برای شما پیامک شده را وارد کنید',
              loading: false
            }, async () => {
              await localStorage.setItem('timerCreated', JSON.stringify([this.state.timerCreatedAt, this.state.phone]));
            });
          } else {
            swal("", "خطایی در ارسال کد رخ داده است.", "error", "تایید");
            this.setState({loading: false});
          }
          //       else {
          //         this.setState({
          //           loading: false,
          //           failureModalToggle: true,
          //           error: 'این شماره موبایل نمی‌تواند به عنوان مشتری ثبت نام کند.'
          //         });
          // }
          // } else
          //   this.setState({auth: 'verification', loading: false}, () => this.resendCode());
          // } else {
          //   this.setState({
          //     loading: false,
          //     failureModalToggle: true,
          //     error: 'خطایی در بررسی شماره همراه رخ داده است.'
          //   });
          // }
        }).catch(error => {
          swal("", "خطایی در ارسال کد رخ داده است." + "\n" + JSON.stringify(error), "error", "تایید");
          this.setState({loading: false});
        });
      });
  };

  updateVerification = e => {
    this.setState({verification: numbersToEn(e.target.value)});
  };

  verifyPhone = e => {
    e.preventDefault();
    this.setState({loading: true});
    verifyCode('0' + this.state.phone, this.state.verification).then(response => {
      if (response
          // parseInt(this.state.verification) === parseInt(this.state.code)
      ) {
        this.authenticate();
        // const user = {firstName: "", lastName: "", phone: this.state.phone, roleName: "Vendor"};
      } else {
        swal("", "کد وارد شده اشتباه است.", "error", "تایید");
        this.setState({loading: false});
      }
    });
  };

  authenticate = () => {
    passwordAuth('0' + this.state.phone, this.state.verification).then(async response => {
      if (response.access_token) {
        await localStorage.removeItem('timerCreated');
        const access_token = response.access_token;
        getUser('0' + this.state.phone, access_token).then(async response => {
          if (response.status !== 200) {
            await this.setState({loading: false});
            await swal(response.title, response.message, "error", "تایید");
          } else if (response.user.is_disabled) {
            await this.setState({loading: false});
            // await this.logout(access_token);
            await this.props.logout();
            await removeFromStorage('user');
            await swal("", "حساب کاربری شما توسط مدیریت سامانه غیرفعال شده است.", "error", "تایید");
          } else {
            const user = {
              access_token: access_token,
              firstName: response.user.firstName,
              lastName: response.user.lastName,
              roleName: response.user.roleName,
              status: response.user.status,
              nationalCode: response.user.nationalCode,
              bankAccount:response.user.bankAccount,
              phone: response.user.phone,
              email: response.user.email,
              emailVerifiedAt: response.user.emailVerifiedAt,
              birthDate: response.user.birthDate,
              gender: response.user.gender,
              address: response.user.address,
              avatar: response.user.avatar,
              score: response.user.score,
              createdAt: response.user.jalaliCreatedAt,
              updatedAt: response.user.updatedAt,
              wallet: response.user.wallet
            };
            await setInStorage('user', user);
            await this.props.login(user);
            await this.setState({loading: false});
            this.props.history.push('/dashboard');
          }
        }).catch(error => {
          swal("", "خطایی در برقراری ارتباط با سرور رخ داده است." + "\n" + JSON.stringify(error), "error", "تایید");
          this.setState({loading: false});
        });
      } else {
        await this.setState({loading: false});
        await swal("", "شماره موبایل یا کد اشتباه است.", "error", "تایید");
      }
    }).catch(error => {
      swal("", "خطایی در برقراری ارتباط با سرور رخ داده است." + "\n" + JSON.stringify(error), "error", "تایید");
      this.setState({loading: false});
    });
  };

  countDownRenderer = ({minutes, seconds}) => {
    return <span>{minutes}:{seconds}</span>;
  };

  editPhone = () => {
    if (this.state.verificationTimer !== 0 || this.state.loading)
      this.setState({step: 1, code: '', verification: ''});
  };

  renderForm = () => {
    switch (this.state.step) {
      case 1:
        return (
            <form id="loginForm" onSubmit={this.checkPhone}>
              <MDBInput
                  id="phone" label="شماره موبایل" labelId="phoneLabel" icon="phone"
                  group type="tel" validate error="wrong" success="right" name="phone"
                  value={this.state.phone} onInput={this.updatePhone}
                  className="input-font-spacing d-inline-block pl-5 mb-0"
                  style={{width: "250px", direction: "ltr"}}>
                <span className="font-weight-bold input-font-spacing mr-n5">98+</span>
              </MDBInput>
              {this.state.loading ?
                  <div className="text-center"><CircularProgress color="primary"/></div>
                  :
                  <MDBBtn block className="mt-5" type="submit"
                          disabled={(!(this.state.phone.length === 10 && !this.state.loading))}>تأیید
                  </MDBBtn>
              }
            </form>
        );

      case 2:
        return (
            <form id="loginForm" onSubmit={this.verifyPhone}>
              <MDBInput
                  id="verification" label="کد تایید هویت" labelId="verificationLabel" icon="shield-alt"
                  group type="text" validate error="wrong" success="right" name="verification"
                  value={this.state.verification} onInput={this.updateVerification}
                  className="input-font-spacing" style={{width: "200px", direction: "ltr"}}
              />
              {this.state.loading ?
                  <div className="text-center"><CircularProgress color="primary"/></div>
                  :
                  <MDBBtn
                      block className="mt-5" type="submit"
                      disabled={(!(this.state.verification.length === 6 && !this.state.loading))}>تأیید
                  </MDBBtn>
              }
              {!this.state.loading ?
                  <div className="text-center mt-3">
                    <MDBBtn type="button" onClick={this.checkPhone}
                            disabled={(this.state.verificationTimer !== 0 || this.state.loading)}>
                      ارسال مجدد کد ({
                      <Countdown
                          key={this.state.key}
                          date={this.state.timerCreatedAt + this.state.verificationTimer}
                          autoStart={true}
                          renderer={this.countDownRenderer}
                          onComplete={() => this.setState({verificationTimer: 0}, async () => {
                            await localStorage.removeItem('timerCreated');
                          })}
                      />
                    })
                    </MDBBtn>
                    <MDBBtn type="button" onClick={this.editPhone}
                            disabled={(this.state.verificationTimer !== 0 || this.state.loading)}>
                      تغییر شماره موبایل
                    </MDBBtn>
                  </div>
                  : null}
            </form>
        );

      default:
        return null;
    }
  };

  render() {
    return (
        <MDBContainer fluid className="px-0" style={{height: '100vh'}}>
          <MDBRow className="no-gutters h-100">
            <MDBCol md="6">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask opacity-7 bg-dark"/>
                <div className="hero-bg hero-bg-scroll"
                     style={{backgroundImage: `url(${image3})`}}/>
                <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                  <MDBRow className="no-gutters">
                    <MDBCol size="10" lg="9" className="mx-auto">
                      <div className="logo mt-5 mb-5 mb-md-0">
                        <MDBLink className="d-flex" to="/" title="همیان">
                          <img src={logo} width="auto" height="100" alt="همیان"/>
                          <h1 className="font-weight-bold mr-3 mt-4">همیان</h1>
                        </MDBLink>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="no-gutters my-auto">
                    <MDBCol size="10" lg="9" className="mx-auto">
                      <h1 className="text-11 text-white mb-4">خوش آمدید</h1>
                      <p className="text-4 text-white line-height-4 mb-5">
                        از اینکه کیف پول همیان را انتخاب کردید سپاسگزاریم. برای ورود یا عضویت کافیست شماره موبایل خود را
                        وارد کنید.
                      </p>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="6" className="d-flex align-items-center">
              <MDBContainer className="my-5">
                <MDBRow>
                  <MDBCol size="11" lg="9" xl="8" className="mx-auto">
                    <h3 className="font-weight-400 mb-4">ورود به حساب کاربری</h3>
                    <h5 className="font-weight-bold mb-4">{this.state.title}</h5>
                    {this.renderForm()}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: user => dispatch({type: "LOGIN", payload: user}),
    logout: () => dispatch({type: "LOGOUT"})
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
