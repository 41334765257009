import swal from "sweetalert";

export const _numberWithCommas = (x) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const _areArraysEqual = (arr1, arr2) => {
  if (!arr1 || !arr2 || !(arr1 instanceof Array) || !(arr2 instanceof Array)) return;
  let result = true;
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      result = false;
      break;
    }
  }
  return result
};

const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
export const _numbersToEn = input => {
  for (let i = 0; i < 10; i++)
    input = input.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
  return _isNumber(input) ? input : _toNumber(input);
};

export const _isNumber = str => {
  const pattern = /^\d+$/;
  return pattern.test(str);
};

export const _toNumber = str => {
  return str.replace(/\D/g, '');
};

export const _dashed = number => {
  if (number)
    return number.toString().match(/.{1,4}/g).join('-');
  return '';
};

export const _swal = (title, message, color, button) => {
  return swal(title, message, color, {
    button: button,
  });
};

export const _formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const _nFormatter = (num, digits = 1) => {
  const si = [
    {value: 1, symbol: ""},
    {value: 1E3, symbol: "K"},
    {value: 1E6, symbol: "M"},
    {value: 1E9, symbol: "G"},
    {value: 1E12, symbol: "T"},
    {value: 1E15, symbol: "P"},
    {value: 1E18, symbol: "E"}
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const _convertHMS = (value) => {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let seconds = sec - (hours * 3600) - (minutes * 60);
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};
