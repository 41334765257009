import React from 'react';
import './AutoComplete.css';
import {MDBInput} from "mdbreact";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Close from '@material-ui/icons/Close';

export default class AutoComplete extends React.Component {
  static defaultProps = {
    suggestions: [],
    value: '',
    onChange: null,
    onKeyDown: null,
    onSelect: null,
    id: '',
    label: '',
    labelId: '',
    icon: '',
    type: '',
    error: '',
    success: '',
    name: '',
    suggestionLabel: null,
    loading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      showSuggestions: false
    };
  }

  onChange = e => {
    this.props.onChange(e.target.value);
  };

  onKeyDown = e => {
    this.props.onKeyDown(e);
  };

  onSelect = s => {
    this.props.onSelect(s);
  };

  render() {
    return (
        <MDBInput
            id={this.props.id} label={this.props.label} labelId={this.props.labelId} icon={this.props.icon}
            group type={this.props.type} validate error={this.props.error} success={this.props.success}
            name={this.props.name} value={this.props.value} onInput={this.onChange} onKeyDown={this.onKeyDown}
            onFocus={() => this.setState({showSuggestions: true})}
            onBlur={() => setTimeout(() => this.setState({showSuggestions: false}), 100)}
            className="mb-0" containerClass="mb-0"
        >
          <IconButton component="span" onClick={this.props.onClear}
                      className="position-absolute close-autocomplete"><Close/></IconButton>
          {this.state.showSuggestions ?
              <List component="ul" className="suggestions">
                {
                  this.props.loading ?
                      <div className="text-center"><CircularProgress color="primary"/></div>
                      :
                      this.props.suggestions.length ?
                          this.props.suggestions.map((suggestion, index) =>
                              <ListItem key={index} button onClick={() => this.onSelect(suggestion)}>
                                <ListItemText>
                                  {this.props.suggestionLabel(suggestion)}
                                </ListItemText>
                              </ListItem>
                          )
                          :
                          !this.props.value.length ?
                              <p className="mt-4 pr-2">لطفا شماره کارت مورد نظر را وارد کنید...</p>
                              :
                              <p className="mt-4 pr-2">موردی یافت نشد...</p>
                }
              </List>
              : null}
        </MDBInput>
    );
  }
}
