import React from "react";
import {Main} from "../../layouts";
import {Hero, WhyChooseUs, HowItWorks, WhatCanYouDo, Testimonials, MobileApp} from '../../components';

export default class Home extends React.Component {
  render() {
    return (
        <Main>
          <Hero/>
          <WhyChooseUs/>
          <HowItWorks/>
          <WhatCanYouDo/>
          <Testimonials/>
          <MobileApp/>
        </Main>
    );
  }
}