import React from 'react';
import './Footer.css';
import {MDBBtn, MDBCol, MDBContainer, MDBInputGroup, MDBNav, MDBNavItem, MDBNavLink, MDBRow} from "mdbreact";

export default class Footer extends React.Component {
  render() {
    return (
        <footer id="footer" className="pb-4">
          {!this.props.onlyCopyRight ?
              <MDBContainer>
                <MDBRow>
                  <MDBCol sm="6" size="md" className="mb-3 mb-md-0">
                    <h4 className="text-3 text-muted text-uppercase font-weight-400 mb-3">Information</h4>
                    <MDBNav className="flex-column">
                      <MDBNavItem><MDBNavLink to="#">About Us</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Careers</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Affiliate</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Fees</MDBNavLink></MDBNavItem>
                    </MDBNav>
                  </MDBCol>
                  <MDBCol sm="6" size="md" className="mb-3 mb-md-0">
                    <h4 className="text-3 text-muted text-uppercase font-weight-400 mb-3">Services</h4>
                    <MDBNav className="flex-column">
                      <MDBNavItem><MDBNavLink to="#">Transfer Money</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Send Money</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Receive Money</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Online Shopping</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Pay bill</MDBNavLink></MDBNavItem>
                    </MDBNav>
                  </MDBCol>
                  <MDBCol sm="6" size="md" className="mb-3 mb-md-0">
                    <h4 className="text-3 text-muted text-uppercase font-weight-400 mb-3">Help Center</h4>
                    <MDBNav className="flex-column">
                      <MDBNavItem><MDBNavLink to="#">Contact Us</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Support</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">Help</MDBNavLink></MDBNavItem>
                    </MDBNav>
                  </MDBCol>
                  <MDBCol sm="6" size="md" className="mb-3 mb-md-0">
                    <h4 className="text-3 text-muted text-uppercase font-weight-400 mb-3">Keep in touch</h4>
                    <MDBNav className="flex-column">
                      <MDBNavItem><MDBNavLink to="#">facebook</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">twitter</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">google</MDBNavLink></MDBNavItem>
                      <MDBNavItem><MDBNavLink to="#">youtube</MDBNavLink></MDBNavItem>
                    </MDBNav>
                  </MDBCol>
                  <MDBCol size="12" lg="3">
                    <h4 className="text-3 text-muted text-uppercase font-weight-400 mb-3">Subscribe</h4>
                    <p>Subscribe to receive latest news and updates.</p>
                    <MDBInputGroup
                        containerClassName="newsletter" material hint="Your Email Address"
                        append={<MDBBtn color="secondary" className="mt-0 mr-n4" type="submit">
                          <i className="fas fa-paper-plane"/></MDBBtn>}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              : null}
          <div className="footer-copyright pt-4 mt-4">
            <MDBContainer>
              <MDBRow>
                <MDBCol size="lg">
                  <p className="text-center text-lg-right mb-2 mb-lg-0">Copyright &copy; 2019 &nbsp;
                    <a href="#" target="_blank">همیان</a>.
                    All Rights Reserved.</p>
                </MDBCol>
                <MDBCol size="lg" className="d-lg-flex align-items-center justify-content-lg-end">
                  <MDBNav className="justify-content-center">
                    <MDBNavItem><MDBNavLink active to="#">Security</MDBNavLink></MDBNavItem>
                    <MDBNavItem><MDBNavLink to="#">Terms</MDBNavLink></MDBNavItem>
                    <MDBNavItem><MDBNavLink to="#">Privacy</MDBNavLink></MDBNavItem>
                  </MDBNav>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </footer>
    );
  }
}