import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import {store} from "./store";
import {Router} from "./routes";
import {getFromStorage, removeFromStorage, setInStorage} from "./services/Storage";
import {checkClientAuth, clientAuth} from "./services/GuestApi";
import {checkPasswordAuth, getUser} from "./services/UserApi";
import {Loading} from "./components";
import {StylesProvider, jssPreset, ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {create} from 'jss';
import rtl from 'jss-rtl';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const theme = createMuiTheme({direction: 'rtl'});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      clientLoaded: false,
      userLoaded: false,
      launch: false
    };
  }

  async componentDidMount() {
    await window.addEventListener('load', async () => {
      await setTimeout(async () => await this.setState({loaded: true}), 250);
    });
    await getFromStorage("client").then(async response => {
      if (response) {
        const jwt = response;
        await checkClientAuth(jwt.access_token).then(async response => {
          if (response)
            await store.dispatch({
              type: "SET_CLIENT_ACCESS_TOKEN",
              payload: jwt
            });
        }).catch(async error => {
          await clientAuth().then(async response => {
            if (response.access_token) {
              await store.dispatch({
                type: "SET_CLIENT_ACCESS_TOKEN",
                payload: response
              });
              await setInStorage('client', response);
            } else
              alert('Error Connecting To Server !');
          });
          await setTimeout(async () => await this.setState({clientLoaded: true}), 250);
        });
      } else
        await clientAuth().then(async response => {
          if (response.access_token) {
            await store.dispatch({
              type: "SET_CLIENT_ACCESS_TOKEN",
              payload: response
            });
            await setInStorage('client', response);
          } else
            alert('Error Connecting To Server !');
        });
      await setTimeout(async () => await this.setState({clientLoaded: true}), 250);
    });

    await getFromStorage("user").then(async response => {
      if (response) {
        const jwt = response;
        await checkPasswordAuth(jwt.access_token).then(async response => {
          if (response) {
            getUser(jwt.phone, jwt.access_token).then(async response => {
              if (response.status === 200) {
                const user = {
                  access_token: jwt.access_token,
                  firstName: response.user.firstName,
                  lastName: response.user.lastName,
                  roleName: response.user.roleName,
                  status: response.user.status,
                  nationalCode: response.user.nationalCode,
                  bankAccount: response.user.bankAccount,
                  phone: response.user.phone,
                  email: response.user.email,
                  emailVerifiedAt: response.user.emailVerifiedAt,
                  birthDate: response.user.birthDate,
                  gender: response.user.gender,
                  address: response.user.address,
                  avatar: response.user.avatar,
                  score: response.user.score,
                  createdAt: response.user.jalaliCreatedAt,
                  updatedAt: response.user.updatedAt,
                  wallet: response.user.wallet
                };
                await setInStorage('user', user);
                await store.dispatch({
                  type: "LOGIN",
                  payload: user
                });
              } else if (response.user.is_disabled) {
                await store.dispatch({type: "LOGOUT"});
                await removeFromStorage('user');
              }
              await setTimeout(async () => await this.setState({userLoaded: true}), 500);
            }).catch(error => {
              setTimeout(async () => await this.setState({userLoaded: true}), 500);
            });
          } else {
            await setTimeout(async () => await this.setState({userLoaded: true}), 500);
          }
        }).catch(async error => {
          if (parseInt(error.message) === 401) {
            await store.dispatch({type: "LOGOUT"});
            await removeFromStorage("user");
          }
          await setTimeout(async () => await this.setState({userLoaded: true}), 500);
        });
      } else {
        await setTimeout(async () => await this.setState({userLoaded: true}), 500);
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {loaded, clientLoaded, userLoaded} = this.state;
    let preloader = document.getElementById('preloader');
    if (preloader && loaded && clientLoaded && userLoaded) {
      preloader.classList.add('fadeOut');
      setTimeout(() => this.setState({launch: true}), 250);
    }
  }

  render() {
    const {launch} = this.state;
    return (
        <Provider store={store}>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
              {launch ? <Router/> : <Loading/>}
            </ThemeProvider>
          </StylesProvider>
        </Provider>
    );
  }
}

export default App;
