import React from "react";
import {numberWithCommas} from "../../assets/js";
import {IncomeModal, OutgoingModal} from "../index";

export default class SideBarWallet extends React.Component {
  render() {
    return (
        <div className="bg-light shadow-sm rounded text-center p-3 mb-4">
          <div className="text-17 text-light my-3"><i className="fas fa-wallet"/></div>
          <h3 className="text-9 font-weight-400">{Object.entries(this.props.user).length ? numberWithCommas(parseInt(this.props.user.wallet) / 10) : 0} تومان</h3>
          <p className="mb-2 text-muted opacity-8">موجودی کیف پول</p>
          <hr className="mx-n3"/>
          <div className="d-flex">
            <IncomeModal/>
            {Object.entries(this.props.user).length ? this.props.user.roleName.toLowerCase() === "vendor" ?
                <OutgoingModal onOutgoingAdded={() => null}/> : null : null}
          </div>
        </div>
    );
  }
}
