import React from "react";
import {Modal} from "../index";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {numbersToEn, swal} from "../../assets/js";
import DefaultSwal from 'sweetalert';
import {updateCard} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class LimitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitModal: false,
      cardNumber: props.card.number.toString(),
      simple: props.card.simpleTransactionLimit ? props.card.simpleTransactionLimit / 10 : '',
      daily: props.card.dailyTransactionLimit ? props.card.dailyTransactionLimit / 10 : '',
      loading: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.limitModal !== nextProps.isOpen)
      this.setState({limitModal: nextProps.isOpen});
  }

  toggleLimitModal = () => {
    this.setState({limitModal: !this.state.limitModal}, () => this.props.onStateChanged(this.state.limitModal));
  };

  updateCard = e => {
    e.preventDefault();
    this.setState({loading: true});
    updateCard(this.state.cardNumber, '', this.state.simple, this.state.daily).then(response => {
      if (response.status !== 200) {
        this.setState({loading: false});
        swal(response.title, response.message, "error", "تایید");
      } else {
        this.props.onLimitSet(this.state.simple, this.state.daily);
        DefaultSwal({title: response.title, text: response.message, icon: "success", buttons: false});
        this.setState({limitModal: false, loading: false}, () => this.props.onStateChanged(this.state.limitModal));
      }
    }).catch(error => {
    });
  };

  render() {
    return (
        <Modal
            toggle={this.toggleLimitModal} noHeader={this.props.noHeader} noBtn={this.props.noBtn}
            title={"تعیین سقف تراکنش ها"}
            btnClassName="p-0" btnColor="link" isOpen={this.state.limitModal} id="limitModal" btnText="به روز رسانی"
        >
          <form id="limitForm" onSubmit={this.updateCard}>
            <MDBModalBody>
              <MDBInput
                  id="simple" label="مبلغ مجاز سقف هر تراکنش را وارد کنید" labelId="simpleLabel" icon="dollar-sign"
                  group type="tel" validate error="wrong" success="right" name="simple"
                  containerClass={this.state.cardNumber.toString().length !== 16 ? "parent-disabled" : ''}
                  className="d-inline-block"
                  value={this.state.simple} onInput={e => this.setState({simple: numbersToEn(e.target.value)})}
                  disabled={this.state.cardNumber.toString().length !== 16}
              >
                <span className="font-weight-bold mr-n5">تومان</span>
              </MDBInput>
              <MDBInput
                  id="daily" label="مبلغ مجاز سقف تراکنش‌های روزانه را وارد کنید" labelId="dailyLabel"
                  icon="dollar-sign"
                  group type="tel" validate error="wrong" success="right" name="daily"
                  containerClass={this.state.cardNumber.toString().length !== 16 ? "parent-disabled" : ''}
                  className="d-inline-block"
                  value={this.state.daily} onInput={e => this.setState({daily: numbersToEn(e.target.value)})}
                  disabled={this.state.cardNumber.toString().length !== 16}
              >
                <span className="font-weight-bold mr-n5">تومان</span>
              </MDBInput>
            </MDBModalBody>
            {this.state.loading ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  <MDBBtn onClick={this.toggleLimitModal}>لغو</MDBBtn>
                  <MDBBtn type="submit"
                          disabled={this.state.cardNumber.toString().length !== 16 || !this.state.simple.length || !this.state.daily.length}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}
