import React, {Fragment} from 'react';
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import {dashed, numberWithCommas} from "../../../assets/js";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default class TransactionRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  renderDescription = row => {
    switch (row.type) {
      case 0:
        if (row.description)
          return (`واریز بابت تراکنش شماره ${row.description}`);
        return ('واریز');
      case 1:
        if (row.description)
          return (`برداشت بابت تراکنش شماره ${row.description}`);
        return ('برداشت');
      case 2:
        return ('شارژ کیف پول از طریق درگاه پرداخت آنلاین');
      case 3:
        return ('بابت تسویه حساب');
      case 4:
        if (row.description)
          return (`کسر کارمزد شارژ کارت مشتری توسط پذیرنده بابت تراکنش شماره ${row.description}`);
        return ('کسر کارمزد شارژ کارت مشتری توسط پذیرنده');
      case 5:
        if (row.description)
          return (`کسر کارمزد تسویه حساب بابت تراکنش شماره ${row.description}`);
        return ('کسر کارمزد تسویه حساب');
    }
  };

  render() {
    const {row} = this.props;
    return (
        <Fragment>
          <TableRow hover style={{borderBottom: this.state.open ? 0 : "1px solid rgba(224, 224, 224, 1)"}}>
            <TableCell component="th" scope="row" padding="none">
              {row.id}
              {/*{row.outgoing ? row.outgoing.transId : row.description}*/}
            </TableCell>
            <TableCell align="center">
              <Moment format="YYYY/MM/DD HH:mm">{row.jalaliCreatedAt}</Moment>
            </TableCell>
            <TableCell align="center" className={row.decAmount ? 'text-danger' : 'text-success'}>
              {row.decAmount ? numberWithCommas(row.decAmount / 10) + '-' : numberWithCommas(row.incAmount / 10) + '+'}
            </TableCell>
            <TableCell align="center">{numberWithCommas(row.balance / 10)}</TableCell>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => this.setState(state => {
                return {open: !state.open}
              })}>
                {this.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit addEndListener={() => null}>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">کارت مبداء</TableCell>
                        <TableCell align="center">کارت مقصد</TableCell>
                        <TableCell align="center">توضیحات</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" style={{minWidth: '200px'}}>
                          {row.source ? dashed(row.source.number) : ''}
                        </TableCell>
                        <TableCell align="center" style={{minWidth: '200px'}}>
                          {row.destination ? dashed(row.destination.number) : ''}
                        </TableCell>
                        <TableCell align="center">
                          {/*{row.description} it's the transId Only*/}
                          {this.renderDescription(row)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
    );
  }
}
