import React from 'react';
import './BackToTop.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class BackToTop extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', e => {
      let backToTop = document.getElementById('back-to-top');
      if (backToTop) {
        if (window.scrollY > 150) {
          backToTop.classList.remove('fadeOut');
          backToTop.classList.add('fadeIn');
        } else {
          backToTop.classList.remove('fadeIn');
          backToTop.classList.add('fadeOut');
        }
      }
    });
  }

  render() {
    return (
        <AnchorLink id="back-to-top" href='#main-wrapper'><i className="fa fa-chevron-up"/></AnchorLink>
    );
  }
}