import React from "react";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {Modal} from "../../components";
import {numbersToEn, swal} from "../../assets/js";
import {assignCardToUser} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from "react-router-dom";

class CardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cardModal: false,
      cardNumber: ''
    };
  }

  toggleCardModal = () => {
    this.setState({cardModal: !this.state.cardModal});
  };

  checkCardNumber = e => {
    if (e.keyCode === 8 && this.state.cardNumber.toString().length)
      this.setState({cardNumber: this.state.cardNumber.slice(0, -1)});
  };

  updateCardNumber = e => {
    if (this.state.cardNumber.toString().length < 16)
      this.setState({cardNumber: numbersToEn(e.target.value)});
  };

  assignCardToUser = e => {
    e.preventDefault();
    this.setState({loading: true});
    assignCardToUser(this.state.cardNumber).then(response => {
      this.setState({loading: false});
      if (response.status !== 200)
        swal(response.title, response.message, "error", "تایید");
      // toast.error('خطایی در ثبت مالکیت کارت رخ داده است.' + '\n' + response.status + '\n' + response.title);
      else {
        swal(response.title, response.message, "success", "تایید");
        this.setState({cardModal: false});
        if (window.location.pathname === "/cards")
          this.props.onCardAdded(response.card);
        else
          this.props.history.push("/cards");
        // toast.success('کارت با موفقیت برای شما ثبت شد.');
      }
    });
  };

  render() {
    return (
        <Modal
            toggle={this.toggleCardModal} title={"افزودن کارت جدید"} btnClassName="p-0 h-100"
            isOpen={this.state.cardModal} id="cardModal" wrapperClassName="h-100" btnColor="transparent"
            btnText={
              <div className="border rounded p-3 text-center h-100">
                <span className="d-block text-10 text-light mt-3 mb-2">
                  <i className="fas fa-plus-circle"/>
                </span>
                افزودن کارت
              </div>
            }>
          <form id="cardForm" onSubmit={this.assignCardToUser}>
            <MDBModalBody>
              <MDBInput
                  id="cardNumber" label="شماره کارت را وارد کنید" labelId="cardNumberLabel" icon="credit-card"
                  group type="tel" validate error="wrong" success="right" name="cardNumber"
                  value={this.state.cardNumber} onInput={this.updateCardNumber} onKeyDown={this.checkCardNumber}
              />
            </MDBModalBody>
            {this.state.loading ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={this.toggleCardModal}>لغو</MDBBtn>
                  <MDBBtn type="submit"
                          disabled={this.state.cardNumber.toString().length !== 16}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}

export default withRouter(CardModal);
