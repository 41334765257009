import React from 'react';
import './PageHeader.css';
import image2 from '../../assets/images/image-2.jpg'

export default class PageHeader extends React.Component {
  static defaultProps = {
    title: '',
    description: ''
  };

  render() {
    return (
        <section className="page-header page-header-text-light py-0 mb-0">
          <section className="hero-wrap section">
            <div className="hero-mask opacity-7 bg-dark"/>
            <div className="hero-bg hero-bg-scroll"
                 style={{backgroundImage: `url(${image2})`}}/>
            <div className="hero-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="text-11 font-weight-500 text-white mb-4">{this.props.title}</h1>
                    <p className="text-5 text-white line-height-4 mb-4">{this.props.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
    );
  }
}