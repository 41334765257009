export const request = {
  get: async (url, access_token, is_client = false) => {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + access_token
    };
    if (is_client)
      headers.is_client = is_client;
    return await fetch(url, {
      method: "GET",
      headers: headers
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    });
  },
  post: async (formData, url, access_token, authHeader = null, encode = false, is_client = false) => {
    let formBody = [];
    for (let property in formData) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(formData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let headers;
    if (access_token)
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token
      };
    else
      headers = {
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json"
      };
    // if (authHeader)
    //   headers.Authorization = authHeader;
    if (is_client)
      headers.is_client = is_client;
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: encode ? formBody : JSON.stringify(formData)
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    });
  }
};
