import React from "react";
import {connect} from "react-redux";
import {SideBarProfile, SideBarWallet, SideBarCard} from "../index";

class SideBar extends React.Component {
  render() {
    return (
        <aside className="col-lg-3">
          <SideBarProfile user={this.props.user}/>
          {!this.props.isCard && !Object.entries(this.props.card).length ?
              <SideBarWallet user={this.props.user}/>
              :
              <SideBarCard card={this.props.card} user={this.props.user} stateChanged={this.props.stateChanged}
                           limitsChanged={this.props.limitsChanged}/>
          }
        </aside>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

export default connect(mapStateToProps, null)(SideBar);
