import React from "react";
import {AutoComplete, Modal} from "../index";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {dashed, numbersToEn, numberWithCommas, swal} from "../../assets/js";
import {cardOutgoing, getSetting, getVendorCards} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class OutgoingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outgoingModal: false,
      cardNumber: props.card ? props.card.number.toString() : '',
      amount: '',
      loading: true,
      requestLoading: false,
      allCards: [],
      cards: []
    }
  }

  componentDidMount() {
    getSetting("OutgoingMinAmount").then(response => {
      if (response.status === 200)
        this.setState({amount: response.setting.value / 10});
      else
        this.setState({amount: '30000'});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.card) {
      if (this.props.isOpen) {
        if (this.props.isOpen !== prevProps.isOpen)
          this.fetchData();
      } else if (this.state.outgoingModal && prevState.outgoingModal !== this.state.outgoingModal) {
        this.fetchData();
      }
    }
  }

  fetchData = () => {
    getVendorCards().then(response => {
      if (response.status === 200)
        this.setState({allCards: response.cards, cards: response.cards, loading: false}, () => {
          if (!this.state.cardNumber.length)
            this.setState({cardNumber: this.state.cards[0].number});
        });
      else {
        this.setState({loading: false});
        swal(response.title, response.message, "error", "تایید");
      }
    });
  };

  toggleOutgoingModal = () => {
    this.setState({outgoingModal: !this.state.outgoingModal})
  };

  checkCardNumber = e => {
    if (e.keyCode === 8 && this.state.cardNumber.toString().length)
      this.setState({cardNumber: this.state.cardNumber.slice(0, -1)});
  };

  updateCardNumber = value => {
    if (this.state.cardNumber.toString().length < 16)
      this.setState({cardNumber: numbersToEn(value), loading: true}, () => {
        if (value)
          this.setState({
            cards: this.state.cards.filter(item => item.number.includes(this.state.cardNumber)),
            loading: false
          });
        else
          this.setState({cards: this.state.allCards, loading: false});
      });
  };

  selectCard = s => {
    this.setState({cards: this.state.allCards, cardNumber: s.number, loading: false});
  };

  clearAutoComplete = () => {
    this.setState({
      cards: this.state.allCards,
      cardNumber: this.props.card ? this.props.card.number.toString() : '',
      amount: '',
      loading: false
    });
  };

  updateAmount = e => {
    this.setState({amount: numbersToEn(e.target.value)});
  };

  cardOutgoing = e => {
    e.preventDefault();
    this.setState({requestLoading: true});
    cardOutgoing(this.state.cardNumber, this.state.amount).then(response => {
      this.setState({requestLoading: false});
      if (response.status !== 200)
        swal(response.title, response.message, "error", "تایید");
      // toast.error('خطایی در انتقال به درگاه پرداخت رخ داده است.' + '\n' + response.status + '\n' + response.title);
      else {
        this.setState({outgoingModal: false});
        swal(response.title, response.message, "success", "تایید");
        this.props.decreaseWallet(parseInt(this.state.amount) * 10);
        if (window.location.pathname.includes("/cards"))
          this.props.onOutgoingAdded(parseInt(this.state.amount) * 10);
        else
          this.props.history.push("/cards");
      }
    });
  };

  render() {
    return (
        <Modal
            toggle={this.props.toggle ? this.props.toggle : this.toggleOutgoingModal}
            title={"درخواست تسویه موجودی کارت"} noHeader={this.props.noHeader} noBtn={this.props.noBtn}
            btnClassName="p-0" btnColor="link" isOpen={this.props.isOpen ? this.props.isOpen : this.state.outgoingModal}
            id="outgoingModal" btnText="درخواست تسویه" wrapperClassName="mr-auto"
        >
          <form id="outgoingForm" onSubmit={this.cardOutgoing}>
            <MDBModalBody>
              {this.props.card ? null :
                  this.state.loading ?
                      <CircularProgress color="primary"/>
                      :
                      <AutoComplete
                          suggestions={this.state.cards} suggestionLabel={suggestion =>
                          <div>
                            <span className="float-right">{dashed(suggestion.number)}</span>
                            <span
                                className="float-left">{numberWithCommas(parseInt(suggestion.balance) / 10)} تومان</span>
                          </div>
                      }
                          value={this.state.cardNumber} onChange={this.updateCardNumber}
                          onKeyDown={this.checkCardNumber} loading={this.state.loading}
                          onSelect={this.selectCard} onClear={this.clearAutoComplete}
                          id="cardNumber" label="شماره کارت را وارد کنید" labelId="cardNumberLabel" icon="credit-card"
                          group type="tel" validate error="wrong" success="right" name="cardNumber"
                      />
              }
              <MDBInput
                  id="amount" label="مبلغ را وارد کنید" labelId="amountLabel" icon="dollar-sign"
                  group type="tel" validate error="wrong" success="right" name="amount"
                  value={this.state.amount} onInput={this.updateAmount}
                  containerClass={this.state.cardNumber.toString().length !== 16 ? "parent-disabled" : ''}
                  className="d-inline-block"
                  disabled={this.state.cardNumber.toString().length !== 16}
              >
                <span className="font-weight-bold mr-n5">تومان</span>
              </MDBInput>
            </MDBModalBody>
            {this.state.requestLoading ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  {/*<MDBLink to="/outgoings" className="btn btn-secondary">مشاهده درخواست ها</MDBLink>*/}
                  <MDBBtn type="submit"
                          disabled={this.state.cardNumber.toString().length !== 16 || !this.state.amount.toString().length}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    decreaseWallet: amount => dispatch({type: "DECREASE_WALLET", payload: amount})
  };
};

export default connect(null, mapDispatchToProps)(withRouter(OutgoingModal));
