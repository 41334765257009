import React from 'react';
import testimonialBg from '../../assets/images/image-4.jpg';
import {MDBContainer} from "mdbreact";

export default class Testimonials extends React.Component {
  render() {
    return (
        <section id="Testimonials" className="hero-wrap section shadow-md">
          <div className="hero-mask opacity-3 bg-dark"/>
          <div className="hero-bg hero-bg-scroll" style={{backgroundImage: `url(${testimonialBg})`}}/>
          <div className="hero-content py-0 py-lg-5 my-0 my-lg-5">
            <MDBContainer className="text-center">
              <h2 className="text-9 text-white font-weight-400 text-uppercase mb-5">What our customers say</h2>
              <a className="video-btn d-flex" href="#">
                <span className="btn-video-play bg-white shadow-md rounded-circle m-auto">
                  <i className="fas fa-play"/>
                </span>
              </a>
            </MDBContainer>
          </div>
        </section>
    );
  }
}