import React from 'react';
import './Main.css';
import {Header, PageHeader, Footer, BackToTop, ScrollToTop} from "../../components";
import {MDBContainer} from "mdbreact";
import {ToastContainer, Flip} from "react-toastify";

export default class Main extends React.Component {
  static defaultProps = {
    isPage: false,
    isProfile: false,
    noHeader: false,
    hasSecondaryNav: false,
    secondaryNav: '',
    noFooter: false,
    onlyCopyRight: false,
    contentClassName: '',
    title: '',
    description: ''
  };

  render() {
    return (
        <div id="main-wrapper">
          {!this.props.noHeader ? <Header isProfile={this.props.isProfile}/> : null}
          {this.props.isPage ? <PageHeader title={this.props.title} description={this.props.description}/> : null}
          {this.props.hasSecondaryNav ?
              <div className="bg-secondary">
                <MDBContainer className="d-flex justify-content-center">
                  {this.props.secondaryNav}
                </MDBContainer>
              </div>
              : null}
          <div id="content" className={this.props.contentClassName}>
            {this.props.children}
          </div>
          {!this.props.noFooter ? <Footer onlyCopyRight={this.props.onlyCopyRight}/> : null}
          <BackToTop/>
          <ToastContainer
              position="top-center" autoClose={10000} hideProgressBar={false} transition={Flip}
              rtl pauseOnVisibilityChange draggable pauseOnHover newestOnTop closeOnClick
          />
          <ScrollToTop/>
        </div>
    );
  }
}