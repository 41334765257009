import React, {Fragment} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from "@material-ui/core/CircularProgress";

export default class DataTable extends React.Component {
  onPageChange = (e, page) => this.props.onPageChange(page);

  onPageSizeChange = e => this.props.onPageSizeChange(e.target.value);

  sort = headCellId => this.props.sort(headCellId);

  sortBy = sortBy => this.props.sortBy(sortBy);

  search = value => this.props.search(value);

  renderToolbar = () => this.props.renderToolbar();

  renderCells = row => this.props.renderCells(row);

  render() {
    const {headCells, rows, rowsPerPage, page, order, orderBy, count, loading, title} = this.props;
    return (
        <Paper className="px-2">
          <Typography variant="h6" id="tableTitle" component="h6" className="pt-3 pr-3">{title}</Typography>
          <Toolbar>
            {this.renderToolbar()}
            {/*<Tooltip title="فیلترها">*/}
            {/*  <IconButton aria-label="filter list">*/}
            {/*    <FilterListIcon/>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </Toolbar>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size='medium' aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headCells.map(headCell =>
                      <TableCell key={headCell.id} align='center' padding='default'>{headCell.label}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ?
                    <TableRow>
                      <TableCell align='center' padding='default' colSpan={5}>
                        <CircularProgress color="primary"/>
                      </TableCell>
                    </TableRow>
                    :
                    rows.length ? rows.map(row => <Fragment key={row.id}>{this.renderCells(row)}</Fragment>) :
                        <TableRow>
                          <TableCell align='center' padding='default' colSpan={5}>
                            <p className="my-3">اطلاعاتی موجود نیست</p>
                          </TableCell>
                        </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 30]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.onPageChange}
              onChangeRowsPerPage={this.onPageSizeChange}
              labelDisplayedRows={({from, to, count}) =>
                  `${from}-${to} از ${count !== -1 ? count : `بیشتر از ${to}`}`
              }
              labelRowsPerPage="تعداد سطرها:"
              ActionsComponent={() =>
                  <div className="w-100 text-center">
                    <IconButton
                        onClick={e => this.onPageChange(e, 0)}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                      <LastPageIcon/>
                    </IconButton>
                    <IconButton onClick={e => this.onPageChange(e, page - 1)} disabled={page === 0}
                                aria-label="previous page">
                      <KeyboardArrowRight/>
                    </IconButton>
                    <IconButton
                        onClick={e => this.onPageChange(e, page + 1)}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                      <KeyboardArrowLeft/>
                    </IconButton>
                    <IconButton
                        onClick={e => this.onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                      <FirstPageIcon/>
                    </IconButton>
                  </div>
              }
          />
        </Paper>
    );
  }
}
