import React from 'react';
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBLink,
  MDBNavbar,
  MDBNavbarNav,
} from "mdbreact";
import './HeaderLogin.css';
import {connect} from "react-redux";
import {removeFromStorage} from "../../services/Storage";
import {withRouter} from "react-router-dom";

class HeaderLogin extends React.Component {
  logout = async () => {
    await this.props.logout();
    await removeFromStorage('user');
    this.props.history.push('/');
  };

  render() {
    return (
        <MDBNavbar className="login-signup" expand>
          <MDBNavbarNav>
            {Object.entries(this.props.user).length ?
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link font-weight-500">پروفایل من</MDBDropdownToggle>
                  <MDBDropdownMenu className="px-2">
                    <MDBLink className="dropdown-item" to="/dashboard">
                      <i className="fas fa-cogs"/>&nbsp; پیشخوان
                    </MDBLink>
                    <hr className="mx-0 my-1"/>
                    <MDBLink className="dropdown-item" to="/profile">
                      <i className="far fa-user"/>&nbsp; مشاهده پروفایل
                    </MDBLink>
                    <hr className="mx-0 my-1"/>
                    <MDBLink className="dropdown-item" to="/cards">
                      <i className="far fa-credit-card"/>&nbsp; کارت های من
                    </MDBLink>
                    <hr className="mx-0 my-1"/>
                    {/*<MDBLink className="dropdown-item" to="/transactions">*/}
                    {/*  <i className="far fa-list-alt"/>&nbsp; تراکنش ها*/}
                    {/*</MDBLink>*/}
                    {/*<hr className="mx-0 my-1"/>*/}
                    {/*{this.props.user.roleName.toLowerCase() === 'vendor' ?*/}
                    {/*    <MDBLink className="dropdown-item" to="/outgoings">*/}
                    {/*      <i className="fas fa-receipt"/>&nbsp; درخواست ها*/}
                    {/*    </MDBLink>*/}
                    {/*    : null}*/}
                    {/*{this.props.user.roleName.toLowerCase() === 'vendor' ? <hr className="mx-0 my-1"/> : null}*/}
                    <MDBLink className="dropdown-item" to="/notifications">
                      <i className="far fa-bell"/>&nbsp; اعلانات
                    </MDBLink>
                    <hr className="mx-0 my-1"/>
                    <MDBDropdownItem tag="a" onClick={this.logout}>
                      <i className="fas fa-sign-out-alt"/>&nbsp;خروج
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
                :
                <li className="align-items-center h-auto mr-sm-3">
                  <MDBLink className="btn btn-default" to="/signin">ورود</MDBLink>
                </li>
            }
          </MDBNavbarNav>
        </MDBNavbar>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch({type: "LOGOUT"})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderLogin));
