import React from "react";
import {DashboardLayout} from "../../layouts";
import {paymentCallback} from "../../services/GuestApi";
import {withRouter} from "react-router-dom";
import {MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import {numberWithCommas} from "../../assets/js";
import {isAndroid, isIOS} from 'react-device-detect';
import Logo from "../../components/Logo/Logo";

class IncomeStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, income: {}, errorTitle: '', errorMessage: ''}
  }

  componentDidMount() {
    let token = new URLSearchParams(this.props.location.search).get("token");
    if (token)
      paymentCallback(token).then(response => {
        let uri = window.location.toString();
        if (uri.indexOf("?") > 0)
          window.history.replaceState({}, document.title, uri.substring(0, uri.indexOf("?")));
        if (response.status === 200) {
          this.setState({income: response.income, loading: false});
        } else {
          this.setState({errorTitle: response.title, errorMessage: response.message, loading: false});
        }
      }).catch(error => console.log('error : ', error));
    else
      this.goTo("/dashboard");
  }

  goTo = route => window.location.replace(window.location.protocol + '//' + window.location.host + route);

  render() {
    return (
        <DashboardLayout noSidebar noHeader>
          <MDBContainer>
            <Logo className="justify-content-center mt-5 pt-5"/>
            <h2 className="font-weight-400 text-center my-4">شارژ کیف من</h2>
            <MDBRow>
              <MDBCol size="12" className="mx-auto">
                {this.state.loading ?
                    <div className="text-center"><CircularProgress color="primary"/></div>
                    :
                    <div className="bg-light shadow-sm rounded p-3 p-sm-4 mb-4">
                      {this.state.errorMessage.length ?
                          <div className="text-center my-5">
                            <p className="text-center text-danger text-20 line-height-07"><i
                                className="fas fa-times-circle"/></p>
                            <p className="text-center text-danger text-8 line-height-07">{this.state.errorTitle}</p>
                            <p className="text-center text-4">{this.state.errorMessage}</p>
                          </div>
                          :
                          Object.entries(this.state.income).length ?
                              <div className="text-center my-5">
                                <p className={`text-center text-${this.state.income.status === 1 ? 'success' : 'danger'} text-20 line-height-07`}>
                                  {this.state.income.status === 1 ?
                                      <i className="fas fa-check-circle"/> : <i className="fas fa-times-circle"/>
                                  }
                                </p>
                                <p className={`text-center text-${this.state.income.status === 1 ? 'success' : 'danger'} text-8 line-height-07`}>
                                  {this.state.income.status === 1 ? 'با موفقیت انجام شد!' : 'ناموفق!'}
                                </p>
                                <p className="text-center text-4">{this.state.income.status === 1 ? 'تراکنش با موفقیت ثبت شد.' : 'تراکنش ناموفق ثبت شد.'}</p>
                                <p className="text-center text-4">کد رهگیری: <span
                                    className="font-weight-500">{this.state.income.transId}</span></p>
                                <p className="text-center text-3 my-4">
                                  مبلغ <span
                                    className="text-4 font-weight-500">{numberWithCommas(parseInt(this.state.income.amount) / 10)} تومان</span>
                                </p>
                                <p className="text-center text-3 my-4">
                                  در تاریخ <span
                                    className="font-weight-500">{this.state.income.jalaliPaymentDate}</span>
                                </p>
                              </div>
                              : null}
                      <div className="text-center">
                        {isAndroid || isIOS ?
                            <a className="btn btn-default" href="hamyan://landing">بازگشت به اپلیکیشن</a>
                            :
                            <MDBBtn className="text-center" onClick={() => this.goTo("/dashboard")}>
                              بازگشت به پیشخوان
                            </MDBBtn>
                        }
                      </div>
                    </div>
                }
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </DashboardLayout>
    );
  }
}

export default withRouter(IncomeStatus);
