import React from 'react';
import hero from '../../assets/images/image-5.jpg';
import {MDBCol, MDBContainer, MDBLink, MDBRow} from "mdbreact";

export default class Hero extends React.Component {
  render() {
    return (
        <section className="hero-wrap section shadow-md pb-4">
          <div className="hero-mask opacity-7 bg-dark"/>
          <div className="hero-bg" style={{backgroundImage: `url(${hero})`}}/>
          <div className="hero-content py-5">
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="6" xl="7" className="my-auto text-center text-lg-right pb-4 pb-lg-0">
                  <h2 className="text-14 font-weight-400 mb-4">همیان،<br className="d-none d-xl-block"/>
                    پول خرد همراه شما
                    {/*<br className="d-none d-xl-block"/>*/}
                    {/*پرداختهای خرد خود را به سادگی و با امنیت بالا انجام دهید*/}
                  </h2>
                  <p className="text-4 text-white mb-4">
                    پرداختهای خرد خود را به سادگی و با امنیت بالا انجام دهید
                  </p>
                  <MDBLink to="/about" className="btn-link text-4 text-light">مشاهده جزییات بیشتر<i
                      className="fas fa-chevron-left text-2 mr-2"/></MDBLink>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </section>
    );
  }
}
