import {Api} from "../config";
import {request} from "./Request";
import {store} from "../store";
import React from "react";

export const passwordAuth = async (username, password) => {
  return await request.post({
    client_id: Api.PasswordGrantClientId,
    client_secret: Api.PasswordGrantClientSecret,
    username: username,
    password: password,
    grant_type: 'password',
    scope: "*"
  }, Api.AuthApi + "/token", null, 'Basic ' + btoa(`${Api.PasswordGrantClientId}:${Api.PasswordGrantClientSecret}`), true);
};

export const checkPasswordAuth = async access_token => {
  return await request.get(Api.AuthApi + "/checkToken", access_token);
};

export const getUser = async (phone, access_token) => {
  return await request.get(Api.Url + `/users/${phone}`, access_token);
};

export const updateUser = async (avatar, firstName, lastName, email, birthDate, gender, address) => {
  return await request.post({
    phone: store.getState().user.user.phone,
    firstName: firstName,
    lastName: lastName,
    email: email,
    birthDate: birthDate,
    gender: gender,
    address: address,
    avatar: avatar
  }, Api.Url + `/users/${store.getState().user.user.phone}`, store.getState().user.user.access_token);
};

export const getRecentTransactions = async () => {
  return await request.get(Api.Url + `/transactions/${store.getState().user.user.phone}/recent`, store.getState().user.user.access_token);
};

export const getCards = async search => {
  return await request.get(Api.Url + `/cards?number=${search}`, store.getState().user.user.access_token);
};

export const getCard = async number => {
  return await request.get(Api.Url + `/cards/${number}`, store.getState().user.user.access_token);
};

export const getCardRecentTransactions = async number => {
  return await request.get(Api.Url + `/cards/${number}/transactions/recent`, store.getState().user.user.access_token);
};

export const getCardTransactions = async (number, perPage, page, orderBy, order, search, dateFrom, dateTo, amountFrom, amountTo, type) => {
  return await request.get(Api.Url + `/cards/${number}/transactions?perPage=${perPage}&page=${page}&orderBy=${orderBy}&order=${order}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}&amountFrom=${amountFrom}&amountTo=${amountTo}&type=${type}`, store.getState().user.user.access_token);
};

export const getUserCards = async (orderBy = "CreatedAt", order = "desc", search = "") => {
  return await request.get(Api.Url + `/users/${store.getState().user.user.phone}/cards?orderBy=${orderBy}&order=${order}&number=${search}`, store.getState().user.user.access_token);
};

export const getVendorCards = async () => {
  return await request.get(Api.Url + `/users/${store.getState().user.user.phone}/vendor-cards`, store.getState().user.user.access_token);
};

export const assignCardToUser = async cardNumber => {
  return await request.post({number: cardNumber}, Api.Url + `/users/${store.getState().user.user.phone}/cards`, store.getState().user.user.access_token);
};

export const updateCard = async (cardNumber, status = '', simple = '', daily = '', password = '') => {
  return await request.post({}, Api.Url + `/cards/${cardNumber}?status=${status}&simple=${simple}&daily=${daily}&password=${password}`, store.getState().user.user.access_token);
};

export const cardOutgoing = async (cardNumber, amount) => {
  return await request.post({
    phone: store.getState().user.user.phone,
    number: cardNumber,
    amount: amount
  }, Api.Url + `/outgoings`, store.getState().user.user.access_token);
};

export const chargeCard = async (cardNumber, amount) => {
  return await request.post({
    number: cardNumber,
    amount: amount,
    phone: store.getState().user.user.phone
  }, Api.Url + "/incomes/token", store.getState().user.user.access_token);
};

export const transferMoney = async (source, password, destination, amount) => {
  return await request.post({
    source: source,
    password: password,
    destination: destination,
    amount: amount,
    phone: store.getState().user.user.phone
  }, Api.Url + "/transactions/transfer-money", store.getState().user.user.access_token);
};

export const getSetting = async key => {
  return await request.get(Api.Url + `/settings/${key}`, store.getState().user.user.access_token);
};
