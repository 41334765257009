import React from 'react';
import './Header.css';
import Logo from "../Logo/Logo";
import HeaderNav from "../HeaderNav/HeaderNav";
import HeaderLogin from "../HeaderLogin/HeaderLogin";
import {MDBContainer} from "mdbreact";

export default class Header extends React.Component {
  static defaultProps = {isProfile: false};

  componentDidMount() {
    window.addEventListener('scroll', e => {
      let header = document.getElementById('header');
      if (header) {
        if (window.scrollY > 85) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    });
  }

  render() {
    return (
        <header id="header" className={`${this.props.isProfile ? 'py-3' : ''}`}>
          <MDBContainer>
            <div className="header-row">
              <div className="header-column justify-content-start">
                <Logo/>
                {!this.props.isProfile ? <HeaderNav/> : null}
              </div>
              <div className="header-column justify-content-end">
                <HeaderLogin/>
              </div>
            </div>
          </MDBContainer>
        </header>
    );
  }
}