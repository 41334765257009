import React from 'react';
import {MDBCol, MDBLink} from "mdbreact";
import {IncomeModal, MoneyTransferModal, OutgoingModal} from "../index";
import {dashed, numberWithCommas} from "../../assets/js";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {updateCard} from "../../services/UserApi";
import logo from "../../assets/images/logo.png";
import {connect} from "react-redux";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: props.card,
      status: props.card.status !== undefined ? props.card.status : 1,
      incomeModal: false,
      outgoingModal: false,
      moneyTransferModal: false,
      anchorEl: null
    };
  }

  toggleIncomeModal = () => {
    this.setState({incomeModal: !this.state.incomeModal, anchorEl: null});
  };

  toggleOutgoingModal = () => {
    this.setState({outgoingModal: !this.state.outgoingModal, anchorEl: null});
  };

  toggleMoneyTransferModal = () => {
    this.setState({moneyTransferModal: !this.state.moneyTransferModal, anchorEl: null});
  };

  block = () => {
    swal(`از ${this.state.status === 1 ? "مسدود" : "فعال"} کردن کارت اطمینان دارید؟`, {
      buttons: {
        cancel: "لغو",
        catch: {
          text: "تایید",
          value: "OK",
        },
      },
    }).then((value) => {
      if (value === "OK")
        updateCard(this.state.card.number, this.state.status === 1 ? 0 : 1).then(response => {
          if (response.status === 200)
            this.setState({card: response.card, status: this.state.status === 1 ? 0 : 1});
          else
            swal(response.title, response.message, "error", {button: "تایید"});
        });
    });
  };

  moreClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  moreClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {card} = this.state;
    return (
        <MDBCol size="12" sm="6" lg="4">
          <div className="account-card bg-primary text-white rounded p-3 mb-4">
            <p className="text-4">{dashed(card.number)}</p>
            {/*<p className="d-flex align-items-center">*/}
            {/*  <span className="account-card-expire text-uppercase d-inline-block opacity-8 ml-2">تاریخ انقضاء</span>*/}
            {/*  <span className="text-4 opacity-9">*/}
            {/*    <Moment format="YYYY/MM">{card.jalaliExpiresAt}</Moment>*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p className="d-flex align-items-center m-0">
              <span className="text-uppercase font-weight-500">
                وضعیت: {this.state.status === 1 ? "فعال" : "مسدود"}
              </span>
            </p>
            {/*<p className="d-flex align-items-center m-0">*/}
            {/*  <span className="text-uppercase font-weight-500">*/}
            {/*    سقف مبلغ هر تراکنش: {numberWithCommas(parseInt(card.simpleTransactionLimit) / 10)} تومان*/}
            {/*  </span>*/}
            {/*</p>*/}
            {/*<p className="d-flex align-items-center m-0">*/}
            {/*  <span className="text-uppercase font-weight-500">*/}
            {/*    سقف مبلغ تراکنش‌های روزانه: {numberWithCommas(parseInt(card.dailyTransactionLimit) / 10)} تومان*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p className="d-flex align-items-center m-0">
              <span
                  className="text-uppercase font-weight-500">موجودی: {numberWithCommas(parseInt(card.balance) / 10)} تومان</span>
              <span className="mr-auto">
                <img src={logo} width="auto" height="30" alt="همیان"/>
                {/*همیان*/}
              </span>
            </p>
            <ul className="account-card-overlay rounded mb-0 pr-0 d-flex text-center">
              <li className="cursor-pointer">
                <MDBLink className="text-white" to={`/cards/${card.number}`}>
                  <span className="ml-2"><i className="fas fa-share"/></span>مشاهده جزییات
                </MDBLink>
              </li>
              <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className="position-absolute text-white" style={{top: 0, right: 0}}
                    onClick={this.moreClick}
                >
                  <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="long-menu" className="py-0"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.moreClose}
                >
                  {/*{card.type === 0 ?*/}
                  <MenuItem onClick={this.toggleIncomeModal}>
                    <span className="ml-2"><i className="fas fa-plus-circle"/></span>شارژ
                  </MenuItem>
                  {/*:*/}
                  {card.type === 1 ?
                      <MenuItem onClick={this.toggleOutgoingModal}>
                        <span className="ml-2"><i className="fas fa-receipt"/></span>درخواست تسویه
                      </MenuItem>
                      : null}
                  {/*}*/}
                  <MenuItem onClick={this.block}>
                    <span className="ml-2"><i
                        className={`fas fa-${this.state.status === 1 ? "ban" : "check"}`}/></span>{this.state.status === 1 ? "مسدود" : "فعال"} کردن
                  </MenuItem>
                  {this.props.user.roleName.toLowerCase() === 'customer' ?
                      <MenuItem onClick={this.toggleMoneyTransferModal}>
                        <span className="ml-2"><i className="fas fa-hand-holding-usd"/></span>
                        انتقال وجه
                      </MenuItem>
                      : this.props.user.roleName.toLowerCase() === 'vendor' && card.type === 1 ?
                          <MenuItem onClick={this.toggleMoneyTransferModal}>
                            <span className="ml-2"><i className="fas fa-hand-holding-usd"/></span>
                            شارژ کارت مشتری
                          </MenuItem>
                          : null}
                </Menu>
              </div>
              {/*{card.type === 0 ?*/}
              {/*    <li onClick={this.toggleIncomeModal} className="mb-3 cursor-pointer">*/}
              {/*      <span className="ml-2"><i className="fas fa-plus-circle"/></span>شارژ*/}
              {/*    </li>*/}
              {/*    :*/}
              {/*    <li onClick={this.toggleOutgoingModal} className="mb-3 cursor-pointer">*/}
              {/*      <span className="ml-2"><i className="fas fa-receipt"/></span>درخواست تسویه*/}
              {/*    </li>*/}
              {/*}*/}
              {/*<li onClick={this.block} className="mb-3 cursor-pointer">*/}
              {/*  <span className="ml-2"><i className="fas fa-ban"/></span>مسدود کردن*/}
              {/*</li>*/}
              {/*<li onClick={this.toggleMoneyTransferModal} className="mb-3 cursor-pointer">*/}
              {/*  <span className="ml-2"><i className="fas fa-hand-holding-usd"/></span>انتقال وجه*/}
              {/*</li>*/}
            </ul>
          </div>
          <IncomeModal
              noBtn card={card} toggle={this.toggleIncomeModal} isOpen={this.state.incomeModal}/>
          <OutgoingModal
              noBtn card={card} toggle={this.toggleOutgoingModal}
              isOpen={this.state.outgoingModal}
              onOutgoingAdded={amount => {
                card.balance -= amount;
                this.setState({outgoingModal: false});
              }}/>
          <MoneyTransferModal
              noBtn card={card} toggle={this.toggleMoneyTransferModal}
              isOpen={this.state.moneyTransferModal}
              onMoneyTransfered={amount => {
                card.balance -= amount;
                this.setState({moneyTransferModal: false});
              }}/>
        </MDBCol>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

export default connect(mapStateToProps, null)(Card);
