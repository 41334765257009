import React from "react";
import {Main} from "../../layouts";
import {MDBLink} from "mdbreact";

export default class PageNotFound extends React.Component {
  render() {
    return (
        <Main isPage>
          <div className="text-center my-5">
            <h2 className="pt-5 mt-5 mb-4">متاسفانه صفحه مورد نظر یافت نشد</h2>
            <MDBLink to="/" className="btn btn-primary d-inline-block mb-5 mt-4">بازگشت به خانه</MDBLink>
          </div>
        </Main>
    );
  }
}