import React from "react";
import {DashboardLayout} from "../../layouts";
import {MDBCol, MDBDropdownMenu, MDBLink, MDBRow} from "mdbreact";
import {CardModal, MoneyTransferModal} from "../../components";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {getRecentTransactions} from "../../services/UserApi";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: []
    };
  }

  componentDidMount() {
    getRecentTransactions().then(response => {
      if (response.status === 200)
        this.setState({
          chartData: response.transactions
          // [
          //   {name: 'A', uv: 4000, pv: 2400, amt: 2400},
          //   {name: 'B', uv: 3000, pv: 1398, amt: 2210},
          //   {name: 'C', uv: 2000, pv: 9800, amt: 2290},
          //   {name: 'D', uv: 2780, pv: 3908, amt: 2000},
          //   {name: 'E', uv: 1890, pv: 4800, amt: 2181},
          //   {name: 'F', uv: 2390, pv: 3800, amt: 2500},
          //   {name: 'G', uv: 3490, pv: 4300, amt: 2100}
          // ]
        });
    });
  }

  render() {
    return (
        <DashboardLayout>
          <div className="bg-light shadow-sm rounded p-4 mb-4">
            <h3 className="text-5 font-weight-400 d-flex align-items-center mb-3">
              دسترسی سریع
            </h3>
            <MDBRow className="profile-completeness">
              <MDBCol sm="6" md="3" className="mb-4 mb-md-0">
                <CardModal onCardAdded={() => null}/>
              </MDBCol>
              <MDBCol sm="6" md="3" className="mb-4 mb-md-0">
                <MDBLink className="p-0 btn btn-block" to="/cards">
                  <div className="border rounded p-3 text-center">
                  <span className="d-block text-10 text-light mt-3 mb-2">
                    <i className="far fa-credit-card"/>
                  </span>
                    کارت های من
                  </div>
                </MDBLink>
              </MDBCol>
              <MDBCol sm="6" md="3" className="mb-4 mb-sm-0">
                {/*<MDBLink className="p-0 btn btn-block" to="/notifications">*/}
                {/*  <div className="border rounded p-3 text-center">*/}
                {/*        <span className="d-block text-10 text-light mt-3 mb-2">*/}
                {/*          <i className="far fa-bell"/>*/}
                {/*        </span>*/}
                {/*    اعلانات*/}
                {/*  </div>*/}
                {/*</MDBLink>*/}
                <MoneyTransferModal/>
              </MDBCol>
              <MDBCol sm="6" md="3">
                <MDBLink className="p-0 btn btn-block" to="/notifications">
                  <div className="border rounded p-3 text-center">
                        <span className="d-block text-10 text-light mt-3 mb-2">
                          <i className="far fa-bell"/>
                        </span>
                    اعلانات
                  </div>
                </MDBLink>
              </MDBCol>
            </MDBRow>
          </div>
          <div className="bg-light shadow-sm rounded p-4 pb-5 mb-4" style={{direction: 'ltr', height: 400}}>
            <h3 className="text-5 font-weight-400 d-flex align-items-center px-4 mb-3" style={{direction: 'rtl'}}>
              تراکنش های امروز
            </h3>
            <ResponsiveContainer>
              <AreaChart data={this.state.chartData} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="jalaliCreatedAtTime"/>
                <YAxis/>
                <Tooltip/>
                <Area type="monotone" dataKey="balance" stroke="#91703B" fill="#DAA520"/>
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/*<div className="bg-light shadow-sm rounded py-4 mb-4">*/}
          {/*  <h3 className="text-5 font-weight-400 d-flex align-items-center px-4 mb-3">فعالیت های اخیر</h3>*/}
          {/*  <div className="transaction-title py-2 px-4">*/}
          {/*    <MDBRow>*/}
          {/*      <MDBCol size="2" sm="1" className="text-center"><span>Date</span></MDBCol>*/}
          {/*      <MDBCol sm="7">Description</MDBCol>*/}
          {/*      <MDBCol size="auto" sm="2" className="d-none d-sm-block text-center">Status</MDBCol>*/}
          {/*      <MDBCol size="3" sm="2" className="text-right">Amount</MDBCol>*/}
          {/*    </MDBRow>*/}
          {/*  </div>*/}
          {/*  <div className="transaction-list">*/}
          {/*    <div className="transaction-item px-4 py-3" data-toggle="modal" data-target="#transaction-detail">*/}
          {/*      <MDBRow className="align-items-center flex-row">*/}
          {/*        <MDBCol size="2" sm="1" className="text-center">*/}
          {/*          <span className="d-block text-4 font-weight-300">16</span>*/}
          {/*          <span className="d-block text-1 font-weight-300 text-uppercase">APR</span>*/}
          {/*        </MDBCol>*/}
          {/*        <MDBCol sm="7">*/}
          {/*          <span className="d-block text-4">HDFC Bank</span>*/}
          {/*          <span className="text-muted">Withdraw to Bank account</span>*/}
          {/*        </MDBCol>*/}
          {/*        <MDBCol size="auto" sm="2" className="d-none d-sm-block text-center text-3">*/}
          {/*          <span className="text-warning"><i className="fas fa-ellipsis-h"/></span>*/}
          {/*        </MDBCol>*/}
          {/*        <MDBCol size="3" sm="2" className="text-right text-4">*/}
          {/*          <span className="text-nowrap">- $562</span>*/}
          {/*          <span className="text-2 text-uppercase">(USD)</span>*/}
          {/*        </MDBCol>*/}
          {/*      </MDBRow>*/}
          {/*    </div>*/}
          {/*    <div className="transaction-item px-4 py-3" data-toggle="modal" data-target="#transaction-detail">*/}
          {/*      <div className="row align-items-center flex-row">*/}
          {/*        <div className="col-2 col-sm-1 text-center"><span*/}
          {/*            className="d-block text-4 font-weight-300">15</span> <span*/}
          {/*            className="d-block text-1 font-weight-300 text-uppercase">APR</span></div>*/}
          {/*        <div className="col col-sm-7"><span className="d-block text-4">Envato Pty Ltd</span> <span*/}
          {/*            className="text-muted">Payment Received</span>*/}
          {/*        </div>*/}
          {/*        <div className="col-auto col-sm-2 d-none d-sm-block text-center text-3"><span*/}
          {/*            className="text-success"*/}
          {/*            data-toggle="tooltip"*/}
          {/*            data-original-title="Completed"><i*/}
          {/*            className="fas fa-check-circle"/></span></div>*/}
          {/*        <div className="col-3 col-sm-2 text-right text-4"><span className="text-nowrap">+ $562</span>*/}
          {/*          <span*/}
          {/*              className="text-2 text-uppercase">(USD)</span></div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="transaction-item px-4 py-3" data-toggle="modal" data-target="#transaction-detail">*/}
          {/*      <div className="row align-items-center flex-row">*/}
          {/*        <div className="col-2 col-sm-1 text-center"><span*/}
          {/*            className="d-block text-4 font-weight-300">04</span> <span*/}
          {/*            className="d-block text-1 font-weight-300 text-uppercase">APR</span></div>*/}
          {/*        <div className="col col-sm-7"><span className="d-block text-4">HDFC Bank</span> <span*/}
          {/*            className="text-muted">Withdraw to Bank account</span>*/}
          {/*        </div>*/}
          {/*        <div className="col-auto col-sm-2 d-none d-sm-block text-center text-3"><span*/}
          {/*            className="text-success"*/}
          {/*            data-toggle="tooltip"*/}
          {/*            data-original-title="Completed"><i*/}
          {/*            className="fas fa-check-circle"/></span></div>*/}
          {/*        <div className="col-3 col-sm-2 text-right text-4"><span className="text-nowrap">- $106</span>*/}
          {/*          <span*/}
          {/*              className="text-2 text-uppercase">(USD)</span></div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="transaction-item px-4 py-3" data-toggle="modal" data-target="#transaction-detail">*/}
          {/*      <div className="row align-items-center flex-row">*/}
          {/*        <div className="col-2 col-sm-1 text-center"><span*/}
          {/*            className="d-block text-4 font-weight-300">28</span> <span*/}
          {/*            className="d-block text-1 font-weight-300 text-uppercase">MAR</span></div>*/}
          {/*        <div className="col col-sm-7"><span className="d-block text-4">Patrick Cary</span> <span*/}
          {/*            className="text-muted">Refund</span>*/}
          {/*        </div>*/}
          {/*        <div className="col-auto col-sm-2 d-none d-sm-block text-center text-3"><span*/}
          {/*            className="text-success"*/}
          {/*            data-toggle="tooltip"*/}
          {/*            data-original-title="Completed"><i*/}
          {/*            className="fas fa-check-circle"/></span></div>*/}
          {/*        <div className="col-3 col-sm-2 text-right text-4"><span className="text-nowrap">+ $60</span>*/}
          {/*          <span*/}
          {/*              className="text-2 text-uppercase">(USD)</span></div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div id="transaction-detail" className="modal fade" role="dialog" aria-hidden="true">*/}
          {/*    <div className="modal-dialog modal-dialog-centered transaction-details" role="document">*/}
          {/*      <div className="modal-content">*/}
          {/*        <div className="modal-body">*/}
          {/*          <div className="row no-gutters">*/}
          {/*            <div className="col-sm-5 d-flex justify-content-center bg-primary rounded-left py-4">*/}
          {/*              <div className="my-auto text-center">*/}
          {/*                <div className="text-17 text-white my-3"><i className="fas fa-building"/></div>*/}
          {/*                <h3 className="text-4 text-white font-weight-400 my-3">Envato Pty Ltd</h3>*/}
          {/*                <div className="text-8 font-weight-500 text-white my-4">$557.20</div>*/}
          {/*                <p className="text-white">15 March 2019</p>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*            <div className="col-sm-7">*/}
          {/*              <h5 className="text-5 font-weight-400 m-3">Transaction Details*/}
          {/*                <button type="button" className="close font-weight-400" data-dismiss="modal"*/}
          {/*                        aria-label="Close">*/}
          {/*                  <span aria-hidden="true">&times;</span></button>*/}
          {/*              </h5>*/}
          {/*              <hr/>*/}
          {/*              <div className="px-3">*/}
          {/*                <ul className="list-unstyled">*/}
          {/*                  <li className="mb-2">Payment Amount <span*/}
          {/*                      className="float-right text-3">$562.00</span></li>*/}
          {/*                  <li className="mb-2">Fee <span className="float-right text-3">-$4.80</span></li>*/}
          {/*                </ul>*/}
          {/*                <hr className="mb-2"/>*/}
          {/*                <p className="d-flex align-items-center font-weight-500 mb-4">Total Amount <span*/}
          {/*                    className="text-3 ml-auto">$557.20</span></p>*/}
          {/*                <ul className="list-unstyled">*/}
          {/*                  <li className="font-weight-500">Paid By:</li>*/}
          {/*                  <li className="text-muted">Envato Pty Ltd</li>*/}
          {/*                </ul>*/}
          {/*                <ul className="list-unstyled">*/}
          {/*                  <li className="font-weight-500">Transaction ID:</li>*/}
          {/*                  <li className="text-muted">26566689645685976589</li>*/}
          {/*                </ul>*/}
          {/*                <ul className="list-unstyled">*/}
          {/*                  <li className="font-weight-500">Description:</li>*/}
          {/*                  <li className="text-muted">Envato March 2019 Member Payment</li>*/}
          {/*                </ul>*/}
          {/*                <ul className="list-unstyled">*/}
          {/*                  <li className="font-weight-500">Status:</li>*/}
          {/*                  <li className="text-muted">Completed</li>*/}
          {/*                </ul>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="text-center mt-4">*/}
          {/*    <MDBLink to="/transactions" className="btn-link text-3">*/}
          {/*      مشاهده همه<i className="fas fa-chevron-left text-2 mr-2"/>*/}
          {/*    </MDBLink>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </DashboardLayout>
    );
  }
}
