const initialState = {
  client: {}
};

export const clientReducer = (state = initialState, action) => {
  if (action.type === "SET_CLIENT_ACCESS_TOKEN") {
    return Object.assign({}, state, {
      client: action.payload
    });
  } else {
    return state;
  }
};