import React from 'react';
import {MDBCol, MDBContainer, MDBLink, MDBRow} from "mdbreact";

export default class HowItWorks extends React.Component {
  render() {
    return (
        <section id="HowItWorks" className="section">
          <MDBContainer>
            <h2 className="text-9 text-center text-uppercase font-weight-400">به سادگی 1-2-3</h2>
            <p className="text-4 text-center font-weight-300 mb-5">Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.</p>
            <MDBRow>
              <MDBCol sm="4" className="mb-4">
                <div className="featured-box style-4">
                  <div className="featured-box-icon text-dark shadow-none border-bottom"><span
                      className="w-100 text-20 font-weight-500">1</span></div>
                  <h3 className="mb-3">Sign Up Your Account</h3>
                  <p className="text-3 font-weight-300">Sign up for your free personal/business Account in fea a
                    minute.</p>
                </div>
              </MDBCol>
              <MDBCol sm="4" className="mb-4">
                <div className="featured-box style-4">
                  <div className="featured-box-icon text-dark shadow-none border-bottom"><span
                      className="w-100 text-20 font-weight-500">2</span></div>
                  <h3 className="mb-3">Send & Receive Money</h3>
                  <p className="text-3 font-weight-300">Receive & Send Payments from worldwide in 40 currencies.</p>
                </div>
              </MDBCol>
              <MDBCol sm="4" className="mb-4 mb-sm-0">
                <div className="featured-box style-4">
                  <div className="featured-box-icon text-dark shadow-none border-bottom"><span
                      className="w-100 text-20 font-weight-500">3</span></div>
                  <h3 className="mb-3">Withdraw Funds</h3>
                  <p className="text-3 font-weight-300">Your funds will be transferred to your local bank account.</p>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="text-center mt-2">
              <MDBLink to="/signin" className="btn btn-primary d-inline-block">Open a Free Account</MDBLink>
            </div>
          </MDBContainer>
        </section>
    );
  }
}