import React from 'react';
import './HeaderNav.css';
import {MDBCollapse, MDBLink, MDBNavbar, MDBNavbarNav, MDBNavbarToggler} from "mdbreact";
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class HeaderNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: 'Home'
    }
  }

  toggleCollapse = () => {
    this.setState({isOpen: !this.state.isOpen}, () => {
      let mobileNavbarToggler = document.getElementById('mobile-navbar-toggler');
      if (mobileNavbarToggler) {
        if (this.state.isOpen)
          mobileNavbarToggler.classList.add('open');
        else
          mobileNavbarToggler.classList.remove('open');
      }
    });
  };

  render() {
    return (
        <div>
          <MDBNavbarToggler id="mobile-navbar-toggler" onClick={this.toggleCollapse}>
            <span/><span/><span/>
          </MDBNavbarToggler>
          <MDBNavbar className="primary-menu" expand="lg">
            <MDBCollapse id="header-nav" isOpen={this.state.isOpen} navbar>
              <MDBNavbarNav className="ml-auto">
                <li>
                  {/*<AnchorLink href='/'>خانه</AnchorLink>*/}
                  <MDBLink to="/" title="همیان">خانه</MDBLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'Home'} to="/">خانه</MDBLink>*/}
                </li>
                <li>
                  <AnchorLink href='#WhyChooseUs'>مزایای همیان</AnchorLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'WhyChooseUs'} to="#WhyChooseUs">مزایای همیان</MDBLink>*/}
                </li>
                <li>
                  <AnchorLink href='#HowItWorks'>راهنما</AnchorLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'HowItWorks'}*/}
                  {/*         to="#HowItWorks">راهنما</MDBLink>*/}
                </li>
                <li>
                  <AnchorLink href='#WhatCanYouDo'>خدمات</AnchorLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'WhatCanYouDo'}*/}
                  {/*         to="#WhatCanYouDo">خدمات</MDBLink></li>*/}
                </li>
                <li>
                  <AnchorLink href='#Testimonials'>صدای مشتریان</AnchorLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'Testimonials'}*/}
                  {/*         to="#Testimonials">صدای مشتریان</MDBLink>*/}
                </li>
                <li>
                  <AnchorLink href='#MobileApp'>دانلود</AnchorLink>
                  {/*<MDBLink onClick={this.smoothScroll} active={this.state.active === 'MobileApp'} to="#MobileApp">دانلود</MDBLink>*/}
                </li>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
        </div>
    );
  }
}
