import React, {Fragment, PureComponent} from 'react';
import Dropzone from 'react-dropzone';
import "./DropZone.css";
import {convertHMS, formatBytes, swal} from "../../assets/js";
import {MDBAlert} from "mdbreact";

const maxFileSize = 2100000;

export default class DropZoneField extends PureComponent {
  static defaultProps = {
    accept: "image/jpeg, image/jpg, image/png",
    customHeight: false,
    height: 0
  };

  onDrop = async file => {
    const {onChange, accept} = this.props;
    const reader = new FileReader();
    if (file.length && file[0] instanceof Blob) {
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        if (accept.includes('video'))
          file.forEach(fl => {
            let video = document.createElement('video');
            video.src = URL.createObjectURL(fl);
            video.addEventListener('loadedmetadata', () => {
              onChange(file.map(fl => Object.assign(fl, {
                preview: URL.createObjectURL(fl),
                fileSize: Math.ceil(fl.size),
                videoDuration: video.duration,
                data: reader.result
              })));
            });
          });
        else
          onChange(file.map(fl => Object.assign(fl, {
            preview: URL.createObjectURL(fl),
            fileSize: Math.ceil(fl.size),
            data: reader.result
          })));
      };
      reader.onerror = (error) => {
        alert('Error: ' + JSON.stringify(error));
      };
    } else {
      await swal("", "فایل مورد نظر شرایط بارگزاری را ندارد.", "error", "تایید");
    }
  };

  removeFile = (index, e) => {
    e.preventDefault();
    const {onChange, value} = this.props;
    onChange(value.filter((val, i) => i !== index));
  };

  render() {
    const {value, customHeight, name, accept, height} = this.props;
    const files = value;
    return (
        <Fragment>
          <MDBAlert color="warning">فرمت های مجاز: {accept}</MDBAlert>
          <MDBAlert color="warning">حداکثر حجم مجاز: {formatBytes(maxFileSize)}</MDBAlert>
          <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}
               style={height ? {height: height} : {}}>
            <Dropzone
                accept={accept}
                maxSize={maxFileSize}
                name={name}
                multiple={false}
                onDrop={(fileToUpload, rejected) => this.onDrop(fileToUpload)}
            >
              {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className="dropzone__input">
                    {(!files || files.length === 0)
                    && (
                        <div className="dropzone__drop-here">
                          <span className="lnr lnr-upload"/>
                          جهت بارگزاری تصویر، کلیک کنید یا تصویر را بکشید و اینجا قرار دهید
                        </div>
                    )}
                    <input {...getInputProps()}/>
                  </div>
              )}
            </Dropzone>
            {files && Array.isArray(files) && files.length > 0
            && (
                <aside className="dropzone__img">
                  {accept.includes('video') ?
                      <video src={files[0].preview} className='h-100'/>
                      :
                      <img src={files[0].preview} alt="drop-img" className="img-fluid"/>
                  }
                  <p className="dropzone__img-name">{files[0].name}</p>
                  <p className="dropzone__img-name dropzone__img-size">{formatBytes(files[0].fileSize)}</p>
                  {accept.includes('video') ?
                      <p className="dropzone__img-name dropzone__img-duration">{convertHMS(files[0].videoDuration)}</p>
                      : null}
                  <button className="dropzone__img-delete btn-danger" type="button"
                          onClick={e => this.removeFile(0, e)}>
                    حذف
                  </button>
                </aside>
            )}
          </div>
        </Fragment>
    );
  }
}
