import React from 'react';
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import appMobile from '../../assets/images/app-mobile.png';
import appStore from '../../assets/images/app-store.png';
import googlePlayStore from '../../assets/images/google-play-store.png';

export default class MobileApp extends React.Component {
  render() {
    return (
        <section id="MobileApp" className="section pt-5 pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" lg="9" xl="8" className="mx-auto">
                <MDBRow>
                  <MDBCol sm="5" md="5" className="text-center align-bottom order-1 order-sm-0">
                    <img className="img-fluid" alt="" src={appMobile}/>
                  </MDBCol>
                  <MDBCol sm="7" md="7" className="my-auto order-0 order-sm-1 text-center text-sm-right">
                    <h2 className="text-8 font-weight-400 text-uppercase mb-3">دریافت اپلیکیشن موبایل</h2>
                    <p className="text-4">Download our app for the fastest, most convenient way to send & get
                      Payment.</p>
                    <div className="pt-2 mb-4 mb-sm-0">
                      <a className="ml-3" href="#">
                        <img className="img-fluid" alt="" src={appStore}/>
                      </a>
                      <a href="#">
                        <img className="img-fluid" alt="" src={googlePlayStore}/>
                      </a>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
    );
  }
}