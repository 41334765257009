import React from "react";
import {Modal} from "../index";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {swal} from "../../assets/js";
import DefaultSwal from 'sweetalert';
import {updateCard} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordModal: false,
      cardNumber: props.card.number.toString(),
      password: props.card.password ? props.card.password : '',
      loading: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.passwordModal !== nextProps.isOpen)
      this.setState({passwordModal: nextProps.isOpen});
  }

  togglePasswordModal = () => {
    this.setState({passwordModal: !this.state.passwordModal}, () => this.props.onStateChanged(this.state.passwordModal));
  };

  updateCard = e => {
    e.preventDefault();
    this.setState({loading: true});
    updateCard(this.state.cardNumber, '', '', '', this.state.password).then(response => {
      if (response.status !== 200) {
        this.setState({loading: false});
        swal(response.title, response.message, "error", "تایید");
      } else {
        DefaultSwal({title: response.title, text: response.message, icon: "success", buttons: false});
        this.setState({
          passwordModal: false,
          loading: false
        }, () => this.props.onStateChanged(this.state.passwordModal));
      }
    }).catch(error => {
    });
  };

  render() {
    return (
        <Modal
            toggle={this.togglePasswordModal} noHeader={this.props.noHeader} noBtn={this.props.noBtn}
            title={"تعیین رمز"}
            btnClassName="p-0" btnColor="link" isOpen={this.state.passwordModal} id="passwordModal"
            btnText="به روز رسانی"
        >
          <form id="passwordForm" onSubmit={this.updateCard}>
            <MDBModalBody>
              <MDBInput
                  id="password" label="رمز برداشت وجه از کارت را وارد کنید" labelId="passwordLabel" icon="lock"
                  group type="password" validate error="wrong" success="right" name="password"
                  containerClass={this.state.cardNumber.toString().length !== 16 ? "parent-disabled" : ''}
                  className="d-inline-block"
                  value={this.state.password} onInput={e => this.setState({password: e.target.value})}
                  disabled={this.state.cardNumber.toString().length !== 16}
              >
              </MDBInput>
            </MDBModalBody>
            {this.state.loading ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  <MDBBtn onClick={this.togglePasswordModal}>لغو</MDBBtn>
                  <MDBBtn type="submit"
                          disabled={this.state.cardNumber.toString().length !== 16 || !this.state.password.length}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}
