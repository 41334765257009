export const setInStorage = async (key, value) => {
  try {
    const result = await localStorage.setItem(key, JSON.stringify(value));
    if (result) return JSON.parse(result);
  } catch (error) {
    return error;
  }
};

export const getFromStorage = async key => {
  try {
    const result = await localStorage.getItem(key);
    if (result) return JSON.parse(result);
  } catch (error) {
    return error;
  }
};

export const removeFromStorage = async key => {
  try {
    const result = await localStorage.removeItem(key);
    if (result) return JSON.parse(result);
  } catch (error) {
    return error;
  }
};

export const clearStorage = async () => {
  try {
    const result = await localStorage.clear();
    if (result) return JSON.parse(result);
  } catch (error) {
    return error;
  }
};
