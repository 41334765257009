import React from "react";
import {Modal} from "../index";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {dashed, numbersToEn, swal} from "../../assets/js";
import DefaultSwal from 'sweetalert';
import {chargeCard, getSetting, getUserCards} from "../../services/UserApi";
import {AutoComplete} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
// import {toast} from "react-toastify";

export default class IncomeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomeModal: false,
      cardNumber: props.card ? props.card.number.toString() : '',
      amount: '',
      userCards: [],
      cards: [],
      loading: false,
      redirecting: false
    }
  }

  componentDidMount() {
    getSetting("ChargeMinAmount").then(response => {
      if (response.status === 200)
        this.setState({amount: response.setting.value / 10});
      else
        this.setState({amount: '1000'});
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.card) {
      if (this.props.isOpen) {
        if (this.props.isOpen !== prevProps.isOpen)
          this.fetchData();
      } else if (this.state.incomeModal && prevState.incomeModal !== this.state.incomeModal) {
        this.fetchData();
      }
    }
  }

  fetchData = () => {
    this.setState({loading: true}, () => {
      getUserCards().then(response => {
        if (response.status === 200)
          this.setState({userCards: response.cards, cards: response.cards, loading: false});
        else {
          this.setState({loading: false});
          swal(response.title, response.message, "error", "تایید");
        }
      });
    });
  };

  toggleIncomeModal = () => {
    this.setState({incomeModal: !this.state.incomeModal})
  };

  checkCardNumber = e => {
    if (e.keyCode === 8 && this.state.cardNumber.toString().length)
      this.setState({cardNumber: this.state.cardNumber.slice(0, -1)});
  };

  updateCardNumber = value => {
    if (this.state.cardNumber.toString().length < 16)
      this.setState({cardNumber: numbersToEn(value), loading: true}, () => {
        if (value)
          getUserCards("CreatedAt", "desc", this.state.cardNumber).then(response => {
            if (response.status === 200)
              this.setState({cards: response.cards, loading: false});
            else {
              this.setState({loading: false});
              swal(response.title, response.message, "error", "تایید");
            }
          });
        else
          this.setState({cards: this.state.userCards, loading: false});
      });
  };

  selectCard = s => {
    this.setState({cards: this.state.userCards, cardNumber: s.number, loading: false});
  };

  clearAutoComplete = () => {
    this.setState({
      cards: this.state.userCards,
      cardNumber: this.props.card ? this.props.card.number.toString() : '',
      amount: '',
      loading: false
    });
  };

  updateAmount = e => {
    this.setState({amount: numbersToEn(e.target.value)});
  };

  chargeCard = e => {
    e.preventDefault();
    this.setState({redirecting: true});
    chargeCard(this.state.cardNumber, this.state.amount).then(response => {
      if (response.status !== 200) {
        this.setState({redirecting: false});
        swal(response.title, response.message, "error", "تایید");
      } else {
        window.location.replace(`https://vandar.io/ipg/test/${response.token}`);
        DefaultSwal({title: response.title, text: response.message, icon: "success", buttons: false});
        this.setState({incomeModal: false, redirecting: false});
      }
    }).catch(error => {
    });
  };

  render() {
    return (
        <Modal
            toggle={this.props.toggle ? this.props.toggle : this.toggleIncomeModal} noHeader={this.props.noHeader}
            noBtn={this.props.noBtn} title={"شارژ کیف من"}
            btnClassName="p-0" btnColor="link" isOpen={this.props.isOpen ? this.props.isOpen : this.state.incomeModal}
            id="incomeModal" btnText="شارژ کیف من"
        >
          <form id="incomeForm" onSubmit={this.chargeCard}>
            <MDBModalBody>
              {this.props.card ? null :
                  <AutoComplete
                      suggestions={this.state.cards} suggestionLabel={suggestion => dashed(suggestion.number)}
                      value={this.state.cardNumber} onChange={this.updateCardNumber} onKeyDown={this.checkCardNumber}
                      onSelect={this.selectCard} onClear={this.clearAutoComplete} loading={this.state.loading}
                      id="cardNumber" label="شماره کارت را وارد کنید" labelId="cardNumberLabel" icon="credit-card"
                      group type="tel" validate error="wrong" success="right" name="cardNumber"
                  />
              }
              <MDBInput
                  id="amount" label="مبلغ را وارد کنید" labelId="amountLabel" icon="dollar-sign"
                  group type="tel" validate error="wrong" success="right" name="amount"
                  containerClass={this.state.cardNumber.toString().length !== 16 ? "parent-disabled" : ''}
                  className="d-inline-block"
                  value={this.state.amount} onInput={this.updateAmount}
                  disabled={this.state.cardNumber.toString().length !== 16}
              >
                <span className="font-weight-bold mr-n5">تومان</span>
              </MDBInput>
            </MDBModalBody>
            {this.state.redirecting ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  <MDBBtn onClick={this.props.toggle ? this.props.toggle : this.toggleIncomeModal}>لغو</MDBBtn>
                  <MDBBtn type="submit"
                          disabled={this.state.cardNumber.toString().length !== 16 || !this.state.amount.length}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}
