import {Api} from "../config";
import {request} from "./Request";
import {store} from "../store";

export const clientAuth = async () => {
  return await request.post({
    client_id: Api.CredentialsGrantClientId,
    client_secret: Api.CredentialsGrantClientSecret,
    grant_type: "client_credentials",
    scope: "*"
  }, Api.AuthApi + "/token", null, 'Basic ' + btoa(`${Api.CredentialsGrantClientId}:${Api.CredentialsGrantClientSecret}`), true);
};

export const checkClientAuth = async access_token => {
  return await request.get(Api.AuthApi + "/checkTokenApi", access_token);
};

export const checkPhone = async phone => {
  return await request.get(Api.AuthApi + `/sendSms?mobile=${phone}`, store.getState().client.client.access_token);
};

export const verifyCode = async (phone, code) => {
  return await request.get(Api.AuthApi + `/verifySmsCode?mobile=${phone}&code=${code}`, store.getState().client.client.access_token);
};

export const paymentCallback = async token => {
  return await request.post({token: token}, Api.Url + `/incomes/callback?token=${token}`, store.getState().client.client.access_token, null, false, true);
};
