import React from 'react';
import {BrowserRouter} from "react-router-dom";
import Switch from "./Animated/Switch";
import Route from "./Animated/Route";
import {connect} from "react-redux";
import {ScrollContext} from "react-router-scroll-4";
import {
  Home,
  AboutUs,
  ContactUs,
  PageNotFound,
  Login,
  Dashboard,
  Profile,
  Cards,
  CardDetails,
  IncomeStatus,
  Outgoings,
  Notifications
} from "../containers";

class Router extends React.Component {
  render() {
    if (Object.entries(this.props.user).length) {
      return (
          <BrowserRouter>
            <ScrollContext>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about" component={AboutUs}/>
                <Route exact path="/contact" component={ContactUs}/>
                <Route exact path="/dashboard" component={Dashboard}/>
                <Route exact path="/profile" component={Profile}/>
                <Route exact path="/cards" component={Cards}/>
                <Route exact path="/cards/:number" component={CardDetails}/>
                <Route exact path="/income-status" component={IncomeStatus}/>
                {this.props.user.roleName.toLowerCase() === 'vendor' ?
                    <Route exact path="/outgoings" component={Outgoings}/> : null}
                <Route exact path="/notifications" component={Notifications}/>
                <Route component={PageNotFound}/>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
      );
    } else {
      return (
          <BrowserRouter>
            <ScrollContext>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about" component={AboutUs}/>
                <Route exact path="/contact" component={ContactUs}/>
                <Route exact path="/signin" component={Login}/>
                <Route exact path="/income-status" component={IncomeStatus}/>
                <Route component={PageNotFound}/>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
      );
    }
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

export default connect(mapStateToProps, null)(Router);
