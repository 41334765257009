const initialState = {
  user: {}
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return Object.assign({}, state, {
        user: action.payload
      });

    case "LOCK":
      return Object.assign({}, state, {
        user: action.payload
      });

    case "LOGOUT":
      return Object.assign({}, state, initialState);

    case "UPDATE":
      return Object.assign({}, state, {
        user: action.payload
      });

    case "CHARGE_WALLET":
      let chargedUser = {...state.user};
      chargedUser.wallet = parseInt(chargedUser.wallet) + parseInt(action.payload);
      return Object.assign({}, state, {
        user: chargedUser
      });

    case "DECREASE_WALLET":
      let sourceUser = {...state.user};
      sourceUser.wallet = parseInt(sourceUser.wallet) - parseInt(action.payload);
      return Object.assign({}, state, {
        user: sourceUser
      });

    default:
      return state;
  }
};
