import React from 'react';
import './Loading.css';
import coin from '../../assets/images/coin.gif';

export default class Loading extends React.Component {
  render() {
    return (
        <div id="preloader"><img src={coin} alt="coin"/></div>
    );
  }
}