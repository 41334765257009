import React from "react";
import {MDBBtn, MDBInput, MDBModalBody, MDBModalFooter} from "mdbreact";
import {AutoComplete, Modal} from "../../components";
import {dashed, numbersToEn, swal} from "../../assets/js";
import {getCards, getUserCards, transferMoney} from "../../services/UserApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class MoneyTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      moneyTransferModal: false,
      source: props.card ? props.card.number.toString() : '',
      destination: '',
      amount: '',
      userCards: [],
      sourceCards: [],
      destinationCards: [],
      password: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.card) {
      if (this.props.isOpen) {
        if (this.props.isOpen !== prevProps.isOpen)
          this.fetchData();
      } else if (this.state.moneyTransferModal && prevState.moneyTransferModal !== this.state.moneyTransferModal) {
        this.fetchData();
      }
    }
  }

  fetchData = () => {
    this.setState({loading: true}, () => {
      getUserCards().then(response => {
        if (response.status === 200) {
          if (this.props.user.roleName.toLowerCase() === 'vendor')
            this.setState({source: response.cards.find(card => card.type === 1)?.number.toString(), loading: false});
          else
            this.setState({userCards: response.cards, sourceCards: response.cards, loading: false});
        } else {
          this.setState({loading: false});
          swal(response.title, response.message, "error", "تایید");
        }
      });
    });
  };

  toggleCardModal = () => {
    this.setState({moneyTransferModal: !this.state.moneyTransferModal});
  };

  updateAmount = e => {
    this.setState({amount: numbersToEn(e.target.value)});
  };

  updatePassword = e => {
    this.setState({password: e.target.value});
  };

  checkSource = e => {
    if (e.keyCode === 8 && this.state.source.toString().length)
      this.setState({source: this.state.source.slice(0, -1)});
  };

  updateSource = value => {
    if (this.state.source.toString().length < 16)
      this.setState({source: numbersToEn(value), loading: true}, () => {
        if (value)
          getUserCards("CreatedAt", "desc", this.state.source).then(response => {
            if (response.status === 200)
              this.setState({sourceCards: response.cards, loading: false});
            else {
              this.setState({loading: false});
              swal(response.title, response.message, "error", "تایید");
            }
          });
        else
          this.setState({sourceCards: this.state.userCards, loading: false});
      });
  };

  selectSource = s => {
    this.setState({sourceCards: this.state.userCards, source: s.number, loading: false});
  };

  clearSource = () => {
    this.setState({
      cards: this.state.userCards,
      source: this.props.card ? this.props.card.number.toString() : '',
      amount: '',
      loading: false
    });
  };

  checkDestination = e => {
    if (e.keyCode === 8 && this.state.destination.toString().length)
      this.setState({destination: this.state.destination.toString().slice(0, -1)});
  };

  updateDestination = value => {
    if (this.state.destination.toString().length < 16)
      this.setState({destination: numbersToEn(value), loading: true}, () => {
        if (value)
          getCards(this.state.destination).then(response => {
            if (response.status === 200)
              this.setState({destinationCards: response.cards, loading: false});
            else {
              this.setState({loading: false});
              swal(response.title, response.message, "error", "تایید");
            }
          });
        else
          this.setState({destination: [], loading: false});
      });
  };

  selectDestination = s => {
    this.setState({destinationCards: [], destination: s.number, loading: false});
  };

  clearDestination = () => {
    this.setState({
      destinationCards: [],
      destination: '',
      loading: false
    });
  };

  transferMoney = e => {
    e.preventDefault();
    this.setState({loading: true});
    transferMoney(this.state.source, this.state.password, this.state.destination, this.state.amount).then(response => {
      this.setState({loading: false});
      if (response.status !== 200) {
        swal(response.title, response.message, "error", "تایید");
      } else {
        this.setState({moneyTransferModal: false});
        swal(response.title, response.message, "success", "تایید");
        this.props.decreaseWallet(parseInt(this.state.amount) * 10);
        if (window.location.pathname.includes("/cards"))
          this.props.onMoneyTransfered(parseInt(this.state.amount) * 10);
        else
          this.props.history.push("/cards");
      }
    });
  };

  render() {
    return (
        <Modal
            noHeader={this.props.noHeader} noBtn={this.props.noBtn}
            toggle={this.props.toggle ? this.props.toggle : this.toggleCardModal}
            title={this.props.user.roleName.toLowerCase() === 'vendor' ? 'شارژ کارت مشتری' : "انتقال وجه (کارت به کارت)"}
            btnClassName="p-0 h-100" wrapperClassName="h-100"
            isOpen={this.props.isOpen ? this.props.isOpen : this.state.moneyTransferModal} id="moneyTransferModal"
            btnText={
              <div className="border rounded p-3 text-center h-100">
                <span className="d-block text-10 text-light mt-3 mb-2">
                  <i className="fas fa-hand-holding-usd"/>
                </span>
                {this.props.user.roleName.toLowerCase() === 'vendor' ? 'شارژ کارت مشتری' : 'انتقال وجه'}
              </div>
            }>
          <form id="cardForm" onSubmit={this.transferMoney}>
            <MDBModalBody>
              {this.props.card ? null :
                  this.props.user.roleName.toLowerCase() === 'vendor' ?
                      <AutoComplete
                          suggestions={[]} suggestionLabel={suggestion => dashed(suggestion.number)}
                          value={this.state.source} loading={this.state.loading}
                          onChange={() => null} onSelect={() => null} onClear={() => null} onKeyDown={() => null}
                          id="source" label="شماره کارت مبدا را وارد کنید" labelId="sourceLabel" icon="credit-card"
                          group type="tel" validate error="wrong" success="right" name="source"
                      />
                      :
                      <AutoComplete
                          suggestions={this.state.sourceCards} suggestionLabel={suggestion => dashed(suggestion.number)}
                          loading={this.state.loading}
                          value={this.state.source} onChange={this.updateSource}
                          onSelect={this.selectSource} onClear={this.clearSource} onKeyDown={this.checkSource}
                          id="source" label="شماره کارت مبدا را وارد کنید" labelId="sourceLabel" icon="credit-card"
                          group type="tel" validate error="wrong" success="right" name="source"
                      />
              }
              <AutoComplete
                  suggestions={this.state.destinationCards} suggestionLabel={suggestion => dashed(suggestion.number)}
                  value={this.state.destination} onChange={this.updateDestination} onKeyDown={this.checkDestination}
                  onSelect={this.selectDestination} onClear={this.clearDestination}
                  id="destination" label="شماره کارت مقصد را وارد کنید" labelId="destinationLabel"
                  icon="credit-card" loading={this.state.loading}
                  group type="tel" validate error="wrong" success="right" name="destination"
              />
              <MDBInput
                  id="amount" label="مبلغ را وارد کنید" labelId="amountLabel" icon="dollar-sign"
                  group type="tel" validate error="wrong" success="right" name="amount"
                  containerClass={`mb-0 ${this.state.source.toString().length !== 16 || this.state.destination.toString().length !== 16 ? "parent-disabled" : ''}`}
                  className="d-inline-block"
                  value={this.state.amount} onInput={this.updateAmount}
                  disabled={this.state.source.toString().length !== 16 || this.state.destination.toString().length !== 16}
              >
                <span className="font-weight-bold mr-n5">تومان</span>
              </MDBInput>
              <MDBInput
                  id="password" label="رمز کارت را وارد کنید" labelId="passwordLabel" icon="lock"
                  group type="text" validate error="wrong" success="right" name="password"
                  containerClass={`mt-0 ${this.state.source.toString().length !== 16 || this.state.destination.toString().length !== 16 ? "parent-disabled" : ''}`}
                  onInput={this.updatePassword}
                  disabled={!this.state.source.password} className="mb-3" value={this.state.password}
              >
                <span className="text-muted pr-3 mr-3">
                  در صورتی که مبلغ بیش از سقف هر تراکنش یا تراکنش های روزانه است.
                </span>
              </MDBInput>
            </MDBModalBody>
            {this.state.loading ?
                <div className="text-center"><CircularProgress color="primary"/></div>
                :
                <MDBModalFooter>
                  <MDBBtn color="secondary"
                          onClick={this.props.toggle ? this.props.toggle : this.toggleCardModal}>لغو</MDBBtn>
                  <MDBBtn type="submit"
                          disabled={this.state.source.toString().length !== 16 || this.state.destination.toString().length !== 16}>تایید</MDBBtn>
                </MDBModalFooter>
            }
          </form>
        </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {...state.user};
};

const mapDispatchToProps = dispatch => {
  return {
    decreaseWallet: amount => dispatch({type: "DECREASE_WALLET", payload: amount})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoneyTransferModal));
