import React from 'react';
import './Logo.css';
import {MDBLink} from "mdbreact";
import logo from '../../assets/images/logo.png';

export default class Logo extends React.Component {
  static defaultProps = {
    className: ''
  };

  render() {
    return (
        <div className="logo">
          <MDBLink className={`d-flex ${this.props.className}`} to="/" title="همیان">
            <img src={logo} width="auto" height="40" alt="همیان"/>
            <h3 className="font-weight-bold mr-2">همیان</h3>
          </MDBLink>
        </div>
    );
  }
}
